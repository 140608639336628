@import '~terra-breakpoints/lib/variables';
@import './mixins';

// Themes
@import './clinical-lowlight-theme/Grid.module';
@import './orion-fusion-theme/Grid.modules';

// Grid Component
// ========================================================
:local {
  .grid {
    box-sizing: border-box;
    display: flex;
    flex-basis: auto;
    flex-direction: row;
    flex-grow: 0;
    flex-shrink: 1;
    flex-wrap: wrap;
    margin-left: calc(var(--terra-grid-gutter-margin-left, 1rem) * -1);
  }

  .column {
    box-sizing: border-box;
    flex-basis: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    max-width: 100%;
    padding-left: var(--terra-grid-gutter-padding-left, 1rem);
  }

  // Create column classes
  @for $i from 1 through 12 {
    .column-#{$i} {
      @include terra-grid-column-size($i);
    }
  }

  // Create responsive styles
  @each $size-name, $size-width in $terra-breakpoints {
    @media screen and (min-width: #{$size-width}px) {
      // Responsive column classes
      /* stylelint-disable max-nesting-depth */
      @for $i from 1 through 12 {
        .column-#{$size-name}-#{$i} {
          @include terra-grid-column-size($i);
        }
      }
      /* stylelint-enable max-nesting-depth */
    }
  }
}
