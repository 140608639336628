// Themes
@import './clinical-lowlight-theme/Input.module';
@import './orion-fusion-theme/Input.module';

:local {
  .form-input {
    background-color: var(--terra-form-input-background-color, #fff);
    background-image: var(--terra-form-input-background-image);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: var(--terra-form-input-background-size);
    border: var(--terra-form-input-border, 1px solid #dedfe0);
    border-radius: var(--terra-form-input-border-radius, 3px);
    box-shadow: var(--terra-form-input-box-shadow);
    color: var(--terra-form-input-color, #1c1f21);
    font-family: inherit;
    font-size: var(--terra-form-input-font-size, 1.142857rem);
    height: var(--terra-form-input-height, 2.142857rem);
    line-height: var(--terra-form-input-line-height, 1.25);
    padding-bottom: var(--terra-form-input-padding-bottom, 0.28571rem);
    padding-left: var(--terra-form-input-padding-left, 0.28571rem);
    padding-right: var(--terra-form-input-padding-right, 0.28571rem);
    padding-top: var(--terra-form-input-padding-top, 0.28571rem);
    transition-duration: var(--terra-form-input-transition-duration, 0s);
    transition-property: background-size;
    transition-timing-function: var(--terra-form-input-transition-timing-function, ease);
    width: 100%;

    &:hover {
      background-color: var(--terra-form-input-hover-background-color, #fff);
      border: var(--terra-form-input-hover-border, 1px solid #dedfe0);
      color: var(--terra-form-input-hover-color);
    }

    &[required] {
      box-shadow: none;
    }

    &[disabled] {
      background-color: var(--terra-form-input-disabled-background-color, rgba(111, 116, 119, 0.2));
      border: var(--terra-form-input-disabled-border, 1px solid #868a8c);
      color: var(--terra-form-input-disabled-color, #1c1f21);
      cursor: default;
      opacity: var(--terra-form-input-disabled-opacity, 0.25);
      pointer-events: none;
    }

    &:focus {
      background-color: var(--terra-form-input-focus-background-color, #fff);
      background-size: var(--terra-form-input-focus-background-size);
      border: var(--terra-form-input-focus-border, 1px solid #4cb2e9);
      box-shadow: var(--terra-form-input-focus-box-shadow);
      color: var(--terra-form-input-focus-color);
      outline: none;

      [dir=ltr] & {
        box-shadow: var(--terra-form-input-focus-ltr-box-shadow);
      }

      [dir=rtl] & {
        box-shadow: var(--terra-form-input-focus-rtl-box-shadow);
      }
    }
  }

  .form-error {
    background-color: var(--terra-form-input-error-background-color, #fff);
    background-image: var(--terra-form-input-error-background-image);
    border: var(--terra-form-input-error-border, 1px solid #e50000);
    box-shadow: var(--terra-form-input-error-box-shadow, 0 0 0 1px #e50000);

    &:hover {
      background-color: var(--terra-form-input-error-hover-background-color, #fff);
      border: var(--terra-form-input-error-hover-border, 1px solid #e50000);
    }

    &[disabled] {
      background-color: var(--terra-form-input-error-disabled-background-color, rgba(111, 116, 119, 0.2));
      border: var(--terra-form-input-error-disabled-border, 1px solid #e50000);
      color: var(--terra-form-input-error-disabled-color, #1c1f21);
      opacity: var(--terra-form-input-error-disabled-opacity, 0.25);
    }

    &[required] {
      box-shadow: var(--terra-form-input-error-box-shadow, 0 0 0 1px #e50000);
    }

    &:focus {
      background-color: var(--terra-form-input-error-focus-background-color, #fff);
      background-size: var(--terra-form-input-error-focus-background-size);
      border: var(--terra-form-input-error-focus-border, 1px solid #e50000);
      box-shadow: var(--terra-form-input-error-focus-box-shadow, 0 0 0 1px #e50000);

      [dir=ltr] & {
        box-shadow: var(--terra-form-input-error-focus-ltr-box-shadow, 0 0 0 1px #e50000);
      }

      [dir=rtl] & {
        box-shadow: var(--terra-form-input-error-focus-rtl-box-shadow, 0 0 0 1px #e50000);
      }
    }
  }

  .form-incomplete {
    background-color: var(--terra-form-input-incomplete-background-color, #ffc);

    &:hover {
      background-color: var(--terra-form-input-incomplete-hover-background-color, #ffc);
    }

    &[disabled] {
      background-color: var(--terra-form-input-incomplete-disabled-background-color, #ffc);
    }

    &:focus {
      background-color: var(--terra-form-input-incomplete-focus-background-color, #ffc);
    }
  }
}
