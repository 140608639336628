// Themes
@import './clinical-lowlight-theme/Slide.module';
@import './orion-fusion-theme/Slide.module';

:local {
  .slide {
    background-color: var(--terra-slide-group-slide-background-color, #fff);
    height: 100%;
    position: absolute;
    width: 100%;

    .slide-shadow {
      background-color: #000;
      display: none;
      height: 100%;
      opacity: 0.5;
      position: absolute;
      transform: translate3d(-100%, 0, 0);
      width: 100%;
    }
  }

  .slide[aria-hidden] {
    visibility: hidden;
  }

  .enter {
    // iOS Safari renders the transition before displaying content if the content is off-screen.
    // 99.99% allows the content to be onscreen enough to correctly render the transtion with the content.
    transform: translate3d(99.99%, 0, 0);

    .slide-shadow {
      display: block;
      opacity: 0;
    }
  }

  .enter.enter-active {
    transform: translate3d(0, 0, 0);

    transition: transform 300ms ease-in-out;

    .slide-shadow {
      display: block;
      opacity: 0.5;
      transition: opacity 300ms ease-in-out;
    }
  }

  .exit {
    transform: translate3d(0, 0, 0);

    .slide-shadow {
      display: block;
      opacity: 0.5;
    }
  }

  .exit.exit-active {
    transform: translate3d(100%, 0, 0);

    transition: transform 300ms ease-in-out;

    .slide-shadow {
      display: block;
      opacity: 0;
      transition: opacity 300ms ease-in-out;
    }
  }
  
  .slide-group {
    height: 100%;
    overflow: hidden;
    position: relative;
  }
}
