@import '~terra-mixins/lib/Mixins';

:local {
  .orion-fusion-theme {
    --terra-list-section-header-background-color: #dedfe0;
    --terra-list-section-header-border-bottom: 1px solid #9b9fa1;
    --terra-list-section-header-padding-bottom: 0.25rem;
    --terra-list-section-header-padding-left: 0.83333rem;
    --terra-list-section-header-padding-top: 0.25rem;
    --terra-list-section-header-title-color: #1c1f21;
    --terra-list-section-header-title-font-size: 1rem;
    --terra-list-section-header-title-font-weight: normal;
    --terra-list-section-header-title-line-height: 1.41667;
    --terra-list-section-header-focus-box-shadow: inset 0 0 2px 0 #dedfe0, inset 0 0 2px 1px #dedfe0, inset 0 0 2px 2px #5e9ed6, inset 0 0 2px 3px #5e9ed6, inset 0 0 2px 4px #c8cacb;
    --terra-list-section-header-focus-outline: none;
    --terra-list-section-header-focus-background-color: #dedfe0;
    --terra-list-section-header-hover-active-background-color: #c8cacb;
    --terra-list-section-header-start-padding-right: 0.25rem;
    --terra-list-section-header-collapsible-padding-left: 0.83333rem;

    @include terra-inline-svg-var('--terra-list-section-header-accordion-icon-background', '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path fill="currentColor" d="M12,0l24,24L12,48V0z"/></svg>');
  }
}
