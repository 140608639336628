:local {
  .clinical-lowlight-theme {
    --terra-spacer-small-minus-2: 0.21429rem;
    --terra-spacer-small-minus-1: 0.35714rem;
    --terra-spacer-small: 0.5rem;
    --terra-spacer-medium: 0.71429rem;
    --terra-spacer-large: 0.85714rem;
    --terra-spacer-large-plus-1: 1.07143rem;
    --terra-spacer-large-plus-2: 1.42857rem;
    --terra-spacer-large-plus-3: 2.14286rem;
    --terra-spacer-large-plus-4: 3.57143rem;
  }
}
