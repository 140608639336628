// Themes
@import '../clinical-lowlight-theme/PopupCommon.module';
@import '../orion-fusion-theme/PopupCommon.module';

:local {
  .popup-count {
    background-color: var(--terra-application-navigation-popup-count-background-color, #fff3ce);
    border: var(--terra-application-navigation-popup-count-border, 1px solid #ffb20b);
    border-radius: 2.143rem;
    color: var(--terra-application-navigation-popup-count-color, #000);
    flex: 0 0 auto;
    font-size: 0.8571rem;
    font-weight: 400;
    line-height: 1.4;
    margin-left: 0.3571rem;
    padding: 0.2143rem 0.5rem;
  }

  .is-hidden {
    border: 0;
    margin: 0;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
    width: 0;
  }
}
