@import '~terra-mixins/lib/Mixins';

:local {
  .orion-fusion-theme {
    // .flex-header
    --terra-action-header-align-items: center;
    --terra-action-header-background-color: #f4f4f4;
    --terra-action-header-border-bottom: 0.16666667rem solid #64696c;
    --terra-action-header-border-left: none;
    --terra-action-header-border-right: none;
    --terra-action-header-border-top: none;
    --terra-action-header-box-shadow: none;
    --terra-action-header-min-height: 2.83333rem;
    --terra-action-header-padding: 0;

    // .flex-collapse
    --terra-action-header-flex-collapse-align-self: center;
    --terra-action-header-flex-collapse-margin: 0.5rem;

    // .flex-fill
    --terra-action-header-flex-fill-align-self: center;

    // .flex-end
    --terra-action-header-flex-end-align-self: center;

    // .title
    --terra-action-header-color: #1c1f21;
    --terra-action-header-font-size: 1.5rem;
    --terra-action-header-font-weight: normal;
    --terra-action-header-title-line-height: 1.25;
    --terra-action-header-title-padding: 0.25rem 0.25rem 0.25rem 0.25rem;
    --terra-action-header-title-padding-top: 0.25rem;

    // .flex-end + .flex-fill
    --terra-action-header-buttons-plus-title-padding-left: 0;
  }
}
