// Themes
@import './clinical-lowlight-theme/PopupOverlay.module';
@import './orion-fusion-theme/PopupOverlay.module';

:local {
  // These are relative to screen coordinates, so no bidi changes needed
  [dir=ltr],
  [dir=rtl] {
    .popup-overlay {
      background-color: var(--terra-popup-overlay-background-color, #fff);
      height: 100%;
      left: 0;
      opacity: var(--terra-popup-overlay-opacity, 0.01);
      overflow: -moz-scrollbars-none;
      -ms-overflow-style: none;
      overflow-x: scroll;
      overflow-y: scroll;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 7000;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .inner {
      height: 200%;
      left: 0;
      position: absolute;
      top: 0;
      width: 200%;
    }
  }
}
