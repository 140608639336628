:local {
  .clinical-lowlight-theme {
    --terra-date-picker-button-border: 1px solid #000;
    --terra-date-picker-button-box-shadow: none;
    --terra-date-picker-button-color: #b2b5b6;
    --terra-date-picker-button-focus-border-color: #000;
    --terra-date-picker-button-focus-box-shadow: 0 0 1px 3px #004c76, 0 0 7px 4px #004c76;
    --terra-date-picker-button-border-bottom-right-radius: 0.25em;
    --terra-date-picker-button-border-top-right-radius: 0.25em;

    --terra-date-picker-input-focus-border-color: #000;

    --terra-date-picker-button-invalid-border-color: #fb4c4c;
    --terra-date-picker-button-invalid-box-shadow: 0 0 0 1px #fb4c4c;
    --terra-date-picker-button-invalid-focus-border-color: #fb4c4c;
    --terra-date-picker-button-invalid-focus-box-shadow: 0 0 0 1px #fb4c4c, 0 0 0 1px #fb4c4c;

    --terra-date-picker-input-invalid-border-color: #fb4c4c;
    --terra-date-picker-input-invalid-box-shadow: 0 0 0 1px #fb4c4c;
    --terra-date-picker-input-invalid-focus-border-color: #fb4c4c;
    --terra-date-picker-input-invalid-focus-box-shadow: 0 0 0 1px #fb4c4c, 0 0 0 1px #fb4c4c;

    --terra-date-picker-input-incomplete-background-color: #464232;
    --terra-date-picker-input-incomplete-focus-background-color: #464232;
    --terra-date-picker-input-incomplete-hover-background-color: #464232;

    --terra-date-picker-input-incomplete-disabled-background-color: #464232;
    --terra-date-picker-input-incomplete-disabled-border: 1px solid #181b1d;
    --terra-date-picker-input-incomplete-disabled-color: #464232;
    --terra-date-picker-input-incomplete-disabled-opacity: 0.3;

    // format text
    --terra-date-picker-format-text-color: #6f7477;
    --terra-date-picker-format-text-font-size: 0.857rem;
    --terra-date-picker-format-text-font-weight: normal;
    --terra-date-picker-format-text-margin-top: 0.357em;
  }
}
