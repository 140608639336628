:local {
  .orion-fusion-theme {
    --terra-avatar-background-clip: padding-box;
    --terra-avatar-border: solid 1px #fff;
    --terra-avatar-color: #fff;
    --terra-avatar-height: 3.286em;
    --terra-avatar-width: 3.286em;
    --terra-avatar-after-box-shadow: inset 0 1px 3px 0 rgba(17, 22, 25, 0.3);
    --terra-avatar-initials-font-size: 1.429em;
    --terra-avatar-initials-font-weight: 400;
    --terra-avatar-neutral-background-color: #0092e0;
    --terra-avatar-neutral-after-box-shadow: inset 0 0 0 2px #0092e0;
    --terra-avatar-one-background-color: #0092e0;
    --terra-avatar-one-after-box-shadow: inset 0 0 0 2px #0092e0;
    --terra-avatar-two-background-color: #0065a3;
    --terra-avatar-two-after-box-shadow: inset 0 0 0 2px #0065a3;
    --terra-avatar-three-background-color: #5c9a32;
    --terra-avatar-three-after-box-shadow: inset 0 0 0 2px #5c9a32;
    --terra-avatar-four-background-color: #406c23;
    --terra-avatar-four-after-box-shadow: inset 0 0 0 2px #406c23;
    --terra-avatar-five-background-color: #f76e00;
    --terra-avatar-five-after-box-shadow: inset 0 0 0 2px #f76e00;
    --terra-avatar-six-background-color: #e34c02;
    --terra-avatar-six-after-box-shadow: inset 0 0 0 2px #e34c02;
    --terra-avatar-seven-background-color: #b400dc;
    --terra-avatar-seven-after-box-shadow: inset 0 0 0 2px #b400dc;
    --terra-avatar-eight-background-color: #7f00a2;
    --terra-avatar-eight-after-box-shadow: inset 0 0 0 2px #7f00a2;
    --terra-avatar-nine-background-color: #0092e0;
    --terra-avatar-nine-after-box-shadow: inset 0 0 0 2px #0092e0;
    --terra-avatar-ten-background-color: #0065a3;
    --terra-avatar-ten-after-box-shadow: inset 0 0 0 2px #0065a3;
    --terra-avatar-is-deceased-background-color: #64696c;
    --terra-avatar-image-is-deceased-opacity: 0.4;
    --terra-avatar-is-deceased-after-box-shadow: inset 0 0 0 2px #64696c;

    @include terra-inline-svg-var('--terra-avatar-icon-facility-background-image', "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' ><path fill='#fff' d='M33,20.2V12H15v8.2H9.8V48h12v-9h4.5v9h12V20.2H33z M17.2,42.8h-3V39h3V42.8z M17.2,33.8h-3V30h3V33.8zM25.5,33.8h-3V30h3V33.8z M29.2,22.5h-3.8v3.8h-3v-3.8h-3.8v-3h3.8v-3.8h3v3.8h3.8V22.5z M33.8,42.8h-3V39h3V42.8z M33.8,33.8h-3V30h3V33.8z'/></svg>");
    @include terra-inline-svg-var('--terra-avatar-icon-shared-user-background-image', "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' ><path fill='#fff' d='M17.2,6c3.6,0,6.5,2.9,6.5,6.5S20.9,19,17.2,19s-6.5-2.9-6.5-6.5S13.6,6,17.2,6z M30.8,14.2c3.6,0,6.5,2.9,6.5,6.5s-2.9,6.5-6.5,6.5s-6.5-2.9-6.5-6.5S27.2,14.2,30.8,14.2z M42,42v-3.3c0-5.3-4.3-9.7-9.7-9.7h-3.1c-5.3,0-9.7,4.4-9.7,9.7V42H42z M25.1,27.5c-1.8-1.5-3-3.8-3.1-6.2c-1-0.3-2-0.5-3.1-0.5h-3.2c-5.4,0-9.8,4.3-9.8,9.7v3.3h12.3C19.6,30.8,22,28.6,25.1,27.5z'/></svg>");
    @include terra-inline-svg-var('--terra-avatar-icon-user-background-image', "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' ><path fill='#fff' d='M24,12c4.7,0,8.5,3.8,8.5,8.5S28.7,29,24,29s-8.5-3.8-8.5-8.5S19.3,12,24,12z M38.6,48v-4.3c0-7-5.6-12.6-12.6-12.6h-4.1c-6.9,0-12.5,5.6-12.5,12.6V48H38.6z'/></svg>");
    @include terra-inline-svg-var('--terra-avatar-icon-provider-background-image',"<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'><path fill='#fff' d='M24 28.9c4.7 0 8.5-3.8 8.5-8.5s-3.8-8.5-8.5-8.5-8.5 3.8-8.5 8.5 3.8 8.5 8.5 8.5zm6 2.9v4.4c0 .3.2.9.9 1.6.6.5.6 1.3.6 1.3v4.5c0 .4-.3.8-.8.8-.4 0-.8-.3-.8-.8v-5.2h-1.5v5.2c0 .4-.3.8-.8.8-.4 0-.8-.3-.8-.8V39s0-.8.6-1.3c.8-.6.9-1.2.9-1.6v-4.7c-.8-.2-1.6-.2-2.4-.2h-4.1c-.8 0-1.6.1-2.4.2v6.9c1.3.3 2.2 1.5 2.2 2.9 0 1.7-1.3 3-3 3s-3-1.3-3-3c0-1.4 1-2.6 2.2-2.9v-6.5c-5 1.7-8.6 6.4-8.6 11.9V48h29.3v-4.3c.1-5.5-3.5-10.2-8.5-11.9zm-12.8 9.4c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5-.7-1.5-1.5-1.5-1.5.7-1.5 1.5z'/></svg>");
  }
}
