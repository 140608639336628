:local {
  .clinical-lowlight-theme {
    --terra-form-field-margin-bottom: 1.428em;
    --terra-form-field-inline-margin-right: 0.7143em;
    --terra-form-field-label-font-size: 1rem;
    --terra-form-field-label-margin-bottom: 0.357em;
    --terra-form-field-label-color: #b2b5b6;
    --terra-form-field-label-font-weight: bold;
    --terra-form-field-label-error-icon-display: none;
    --terra-form-field-label-error-icon-padding-right: 0.357em;
    --terra-form-field-label-required-color: #fb4c4c;
    --terra-form-field-label-required-padding-right: 0.357em;
    --terra-form-field-label-optional-font-style: italic;
    --terra-form-field-label-optional-padding-left: 0.357em;
    --terra-form-field-help-text-color: #6f7477;
    --terra-form-field-help-text-font-size: 0.857rem;
    --terra-form-field-help-text-font-weight: normal;
    --terra-form-field-help-text-margin-top: 0.357em;
    --terra-form-field-error-text-color: #fb4c4c;
    --terra-form-field-error-text-font-size: 0.857rem;
    --terra-form-field-error-text-font-weight: normal;
    --terra-form-field-error-text-margin-top: 0.357em;
  }
}
