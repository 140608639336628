// Themes
@import './orion-fusion-theme/AbstractModal.module';
@import './clinical-lowlight-theme/AbstractModal.module';

:local {
  .abstract-modal {
    display: block;
    left: 50%;
    max-height: calc(100% - 2 * var(--terra-abstract-modal-vertical-inset, 10px));
    max-width: calc(100% - 2 * var(--terra-abstract-modal-horizontal-inset, 10px));
    outline: none;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    &.layer-6000 {
      z-index: 6000 + 1;
    }

    &.layer-7000 {
      z-index: 7000 + 1;
    }

    &.layer-8000 {
      z-index: 8000 + 1;
    }

    &.layer-9000 {
      z-index: 9000 + 1;
    }

    &.is-fullscreen {
      height: 100%;
      width: 100%;
    }
  }
}
