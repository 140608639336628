:local {
  .clinical-lowlight-theme {
    --terra-form-select-dropdown-background-color: #222a2e;
    --terra-form-select-dropdown-border: 1px solid #181b1d;
    --terra-form-select-dropdown-border-bottom-left-radius: 3px;
    --terra-form-select-dropdown-border-bottom-right-radius: 3px;
    --terra-form-select-dropdown-box-shadow: 0 3px 12px -5px #181b1d;
    --terra-form-select-dropdown-above-box-shadow: 0 -3px 12px -5px #181b1d;
    --terra-form-select-dropdown-border-top-left-radius: 3px;
    --terra-form-select-dropdown-border-top-right-radius: 3px;
  }
}
