// Themes
@import '../clinical-lowlight-theme/PopupCommon.module';
@import '../orion-fusion-theme/PopupCommon.module';

:local {
  .item {
    align-items: center;
    color: var(--terra-application-navigation-popup-item-color, #383f42);
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    outline: 0;
    padding: 0.5rem 1.0714285714rem;
    position: relative;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) and (pointer: fine) {
    .item:hover {
      background-color: var(--terra-application-navigation-popup-item-hover-background-color, #f4fafe);
      background-image: var(--terra-application-navigation-popup-item-hover-background-image, linear-gradient(to bottom, #e1f2fd 2%, #ebf6fe 30%, #f4fafe 80%));
      box-shadow: var(--terra-application-navigation-popup-item-hover-box-shadow, inset 0 0 0 1px #fff);
    }
  }

  .item[data-focus-styles-enabled='true']:focus {
    background-color: var(--terra-application-navigation-popup-item-focus-background-color, transparent);
    border-bottom: var(--terra-application-navigation-popup-item-focus-border-bottom, 1px solid #dedfe0);
    outline: var(--terra-application-navigation-popup-item-focus-outline, 2px dashed #000);
    outline-offset: var(--terra-application-navigation-popup-item-focus-outline-offset, -2px);
  }

  .item:active {
    background-color: var(--terra-application-navigation-popup-item-active-background-color, #e1f3fd);
    background-image: var(--terra-application-navigation-popup-item-active-background-image, none);
    box-shadow: var(--terra-application-navigation-popup-item-active-box-shadow, inset 0 0 0 1px #fff);
  }

  .icon,
  .selection-icon {
    color: var(--terra-application-navigation-popup-item-icon-color, #0079be);
    flex: 0 0 auto;
    margin-right: 0.7143rem;
  }

  .selection-icon {
    width: 1rem;
  }

  .text {
    flex: 1 1 auto;
    hyphens: auto;
    overflow-wrap: break-word; /* Modern browsers */
    word-wrap: break-word; /* For IE 10 and IE 11 */
  }

  .extension-row-count {
    flex: 0 0 auto;
    margin-left: 0.5rem;
  }
}
