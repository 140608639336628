// Themes
@import './clinical-lowlight-theme/Base';
@import './orion-fusion-theme/Base';

html {
  background-attachment: var(--terra-base-background-attachment);
  background-color: var(--terra-base-background-color, #fff);
  background-image: var(--terra-base-background-image);
  background-position: var(--terra-base-background-position);
  background-repeat: var(--terra-base-background-repeat);
  background-size: var(--terra-base-background-size);
  // Normalize box-sizing.
  // More info: https://css-tricks.com/box-sizing/#article-header-id-6
  box-sizing: border-box;
  font-family: var(--terra-base-font-family, 'Helvetica Neue', Helvetica, Arial, sans-serif);
  font-size: var(--terra-base-font-size, 87.5%);
  height: var(--terra-base-html-height, 100%);
  margin: var(--terra-base-html-margin, 0);
  text-size-adjust: 100%;
}

body {
  color: var(--terra-base-color, #1c1f21);
  font-size: 1rem;
  height: var(--terra-base-body-height, 100%);
  line-height: var(--terra-base-line-height, 1.4285714285714286);
  margin: var(--terra-base-body-margin, 0);

  @media print {
    background-color: transparent !important;
    color: #000 !important;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
