:local {
  .orion-fusion-theme {
    --terra-form-select-dropdown-background-color: #fff;
    --terra-form-select-dropdown-border: 1px solid #dedfe0;
    --terra-form-select-dropdown-border-bottom-left-radius: 0;
    --terra-form-select-dropdown-border-bottom-right-radius: 0;
    --terra-form-select-dropdown-box-shadow: none;
    --terra-form-select-dropdown-border-top-left-radius: 0;
    --terra-form-select-dropdown-border-top-right-radius: 0;
    --terra-form-select-dropdown-above-box-shadow: none;
  }
}
