:local {
  .orion-fusion-theme {
    --terra-form-select-optgroup-margin: 0 0 1rem 0;
    --terra-form-select-optgroup-padding: 0;
    --terra-form-select-first-optgroup-label-padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    --terra-form-select-optgroup-label-border-top: 1px solid #dedfe0;
    --terra-form-select-optgroup-label-margin: 0;
    --terra-form-select-optgroup-label-background-color: transparent;
    --terra-form-select-optgroup-label-color: #767676;
    --terra-form-select-optgroup-label-font-size: 1rem;
    --terra-form-select-optgroup-label-font-style: normal;
    --terra-form-select-optgroup-label-font-weight: normal;
    --terra-form-select-optgroup-label-line-height: 1.41667;
    --terra-form-select-optgroup-label-padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    --terra-form-select-optgroup-options-margin: 0;
    --terra-form-select-optgroup-options-padding: 0;
  }
}
