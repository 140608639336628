@import './orion-fusion-theme/IconRollUp.module';
@import './clinical-lowlight-theme/IconRollUp.module';

/* stylelint-disable selector-class-pattern */
:local {
  .IconRollup {
    path:first-child {
      fill: var(--terra-icon-roll-up-path-1-fill, currentColor);
    }
  }
}
/* stylelint-enable selector-class-pattern */
