:local {
  .orion-fusion-theme {
    --terra-overlay-light-background: rgba(245, 245, 245, 0.7);
    --terra-overlay-light-background-image: none;
    --terra-overlay-light-content-color: #1c1f21;
    --terra-overlay-light-content-text-shadow: none;
    --terra-overlay-dark-background: rgba(28, 31, 33, 0.8);
    --terra-overlay-dark-background-image: none;
    --terra-overlay-dark-content-color: #fff;
    --terra-overlay-dark-content-text-shadow: none;
    --terra-overlay-content-color: #1c1f21;
    --terra-overlay-content-padding-bottom: 0.5rem;
    --terra-overlay-content-padding-left: 0.5rem;
    --terra-overlay-content-padding-right: 0.5rem;
    --terra-overlay-content-padding-top: 0.5rem;
  }
}

