:local {
  .action-footer,
  .action-footer-end {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
  }

  .action-footer {
    justify-content: space-between;

    &-end {
      justify-content: flex-end;
    }
  }

  .start-actions,
  .end-actions {
    align-self: center;
    flex: 0 1 auto; // Needed for IE 10 to wrap things properly
  }
}
