// Themes
@import './clinical-lowlight-theme/Hyperlink.module';
@import './orion-fusion-theme/Hyperlink.module';

:local {
  .hyperlink {
    background-color: transparent; // Remove the gray background on active links in IE 10
    color: var(--terra-hyperlink-color, #006fc3);
    cursor: pointer;
    display: inline-block; // prevents variant icon from breaking onto its own line
    outline: none;
    text-decoration: var(--terra-hyperlink-text-decoration, none);

    &:visited,
    &.is-visited {
      color: var(--terra-hyperlink-visited-color, #006fc3);

      &:focus {
        color: var(--terra-hyperlink-visited-focus-color, #001f67);
      }
    }
    
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) and (pointer: fine) {
      &:hover,
      &.is-hovered {
        background-color: var(--terra-hyperlink-hover-background-color);
        color: var(--terra-hyperlink-hover-color, #001f67);
        text-decoration: var(--terra-hyperlink-hover-text-decoration, underline);
      }
    }

    &.is-focused {
      background-color: var(--terra-hyperlink-focus-background-color, transparent);
      border-radius: var(--terra-hyperlink-focus-border-radius, 0.2142rem);
      box-shadow: var(--terra-hyperlink-focus-box-shadow, 0 0 0 1px #4cb2e9, 0 0 0 3px rgba(76, 178, 233, 0.25));
      color: var(--terra-hyperlink-focus-color, #001f67);
      text-decoration: var(--terra-hyperlink-focus-text-decoration, underline);
    }

    &.is-active {
      color: var(--terra-hyperlink-active-color, #001f67);
    }

    &.is-disabled {
      color: var(--terra-hyperlink-disabled-color, rgba(28, 31, 33, 0.2));
      cursor: default;
      pointer-events: none;
      text-decoration: var(--terra-hyperlink-disabled-text-decoration, none);
    }
  }

  .is-underline-hidden {
    color: var(--terra-hyperlink-underline-hidden-color, #006fc3);
    text-decoration: none;

    &:focus {
      color: var(--terra-hyperlink-underline-hidden-focus-color, #001f67);
      text-decoration: underline;
    }

    &:hover {
      color: var(--terra-hyperlink-underline-hidden-hover-color, #001f67);
      text-decoration: underline;
    }

    &:visited,
    &.is-visited {
      color: var(--terra-hyperlink-underline-hidden-visited-color, #006fc3);
      text-decoration: underline;

      &:focus {
        color: var(--terra-hyperlink-underline-hidden-visited-focus-color, #001f67);
      }
    }
  }

  .icon {
    margin-left: var(--terra-hyperlink-icon-margin-left, 0.5em);
  }
}
