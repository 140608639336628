@import '~terra-mixins/lib/Mixins';

// Themes
@import './clinical-lowlight-theme/StatusView.module';
@import './orion-fusion-theme/StatusView.module';

/* autoprefixer grid: on */
:local {
  .outer-view {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    justify-items: center;
    padding-bottom: var(--terra-status-view-padding-bottom, 0.714rem);
    padding-top: var(--terra-status-view-padding-top, 0.714rem);
  }

  .is-aligned-top {
    align-content: start;
    
    .top-space {
      flex: 0 0 0;
    }
  }

  .top-space {
    flex: 1 0 0;
  }

  .bottom-space {
    flex: 2 0 0;
  }

  .inner-view {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    max-width: 100%;
    overflow: auto;
  }

  .glyph {
    padding-bottom: 0.714rem;

    > svg,
    .glyph-icon {
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      height: 100px;
      width: 100px;
    }

    > .no-data {
      background: var(--terra-status-view-glyph-no-data-background, inline-svg("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' class='is-bidi'><path fill='rgba(134, 138, 140, 0.15)' d='M45.8 12c1.2 0 2.2 1 2.2 2.2v26.7c0 1.2-1 2.2-2.2 2.2H2.2C1 43 0 42 0 40.8V7.2C0 6 1 5 2.2 5H15c1.4.1 2.7.6 3.7 1.6l3.8 3.9c1 1 2.3 1.5 3.7 1.6l19.4-.1h.2z'/></svg>"));
    }

    > .no-matching-results {
      background: var(--terra-status-view-glyph-no-matching-results-background, inline-svg("<svg data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'><path fill='rgba(134, 138, 140, 0.15)' d='M47.4 43.2l-13-13A19.66 19.66 0 0 0 38 19a19 19 0 1 0-7.9 15.4l13 13a2.05 2.05 0 0 0 2.9 0l1.4-1.4a1.93 1.93 0 0 0 0-2.8zM3 19a16 16 0 1 1 16 16A16 16 0 0 1 3 19z'/></svg>"));
    }

    > .not-authorized {
      background: var(--terra-status-view-glyph-not-authorized-background, inline-svg("<svg data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'><path fill='rgba(134, 138, 140, 0.15)' d='M38.8 20H35v-9a11 11 0 0 0-22 0v9H9.2A2.22 2.22 0 0 0 7 22.2v23.7A2.2 2.2 0 0 0 9.2 48h29.7a2.22 2.22 0 0 0 2.2-2.2V22.2a2.38 2.38 0 0 0-2.3-2.2zM26 33.5V40h-4v-6.5a3.26 3.26 0 0 1-1-2.3 3 3 0 0 1 3-3 3.08 3.08 0 0 1 3 3 3.26 3.26 0 0 1-1 2.3zm6-14.9V20H16v-9a8 8 0 0 1 16 0z'/></svg>"));
    }

    > .error {
      background: var(--terra-status-view-glyph-error-background, inline-svg("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'><path fill='rgba(134, 138, 140, 0.15)' d='M24 0c13.3 0 24 10.7 24 24S37.3 48 24 48 0 37.3 0 24C0 10.8 10.7 0 23.9 0h.1z'/><path fill='#FFF' d='M7 21.5h34v5H7v-5z'/></svg>"));
    }
  }

  .title {
    color: var(--terra-status-view-title-color, #868a8c);
    font-size: var(--terra-status-view-title-font-size, 1.73rem);
    margin: 0;
    overflow-wrap: break-word;
    text-align: center;
    width: 100%;
    word-break: break-word;
    word-wrap: break-word;
  }

  .message {
    color: var(--terra-status-view-message-color, #1c1f21);
    font-size: var(--terra-status-view-message-font-size, 1rem);
    margin: 0;
    overflow-wrap: break-word;
    padding-top: var(--terra-status-view-message-padding-top, 0.714rem);
    text-align: center;
    width: 100%;
    word-break: break-word;
    word-wrap: break-word;
  }

  .divider {
    display: var(--terra-status-view-divider-display, none);
    padding-top: var(--terra-status-view-divider-padding-top, 1.429rem);
  }

  .actions {
    align-content: center;
    align-self: center;
    display: flex;
    flex: 0 0 auto;
    flex-direction: var(--terra-status-view-actions-small-width-flex-direction, row);
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 0.357rem;
    padding-right: 0.357rem;
    padding-top: var(--terra-status-view-actions-padding-top, 0.71428rem);
    width: 100%;
  }

  .button {
    flex: var(--terra-status-view-actions-button-flex, 0 1 auto);
    flex-shrink: 1;
    margin-left: var(--terra-status-view-actions-button-margin-left, 0.357rem);
    margin-right: var(--terra-status-view-actions-button-margin-right, 0.357rem);
    margin-top: var(--terra-status-view-actions-button-margin-top, 0.71428rem);
    max-width: 100%;
  }
}
