:local {
  .clinical-lowlight-theme {
    --terra-list-item-background-color: #222a2e;
    --terra-list-item-min-height: none;
    --terra-list-item-divider-border-top: 1px solid #181b1d;
    --terra-list-item-last-divider-border-bottom: 1px solid #181b1d;
    --terra-list-item-selected-divider-border-color: #181b1d;
    --terra-list-item-selected-divider-border-top-color: #181b1d;
    --terra-list-standard-item-fill-line-height: 1.42857;
    --terra-list-standard-item-fill-padding-bottom: 0.7143rem;
    --terra-list-standard-item-fill-padding-left: 0.7143rem;
    --terra-list-standard-item-fill-padding-right: 0.7143rem;
    --terra-list-standard-item-fill-padding-top: 0.7143rem;
    --terra-list-compact-item-fill-line-height: 1.21428;
    --terra-list-compact-item-fill-padding-bottom: 0.35715rem;
    --terra-list-compact-item-fill-padding-left: 0.7143rem;
    --terra-list-compact-item-fill-padding-right: 0.7143rem;
    --terra-list-compact-item-fill-padding-top: 0.35715rem;
    --terra-list-item-chevron-padding-right: 0.7143rem;
    --terra-list-item-chevron-color: #b2b5b6;
    --terra-list-item-chevron-height: 0.8572rem;
    --terra-list-item-chevron-width: 0.8572rem;
    --terra-list-item-selected-background-color: #004c76;
    --terra-list-item-focus-outline: none;
    --terra-list-item-hover-active-background-color: #1e3a49;
    --terra-list-item-focus-background-color: #1e3a49;
    --terra-list-item-focus-box-shadow: none;
    --terra-list-item-selected-focus-background-color: #004c76;
    --terra-list-item-selected-hover-background-color: #004064;  
  }
}
