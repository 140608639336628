/* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
// Themes
@import './clinical-lowlight-theme/DatePicker.module';
@import './orion-fusion-theme/DatePicker.module';

:local {
  .date-picker {
    display: inline-flex;

    .custom-input {
      display: inline-flex;

      .input {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        vertical-align: middle;
        width: 8em;

        &:focus {
          border-color: var(--terra-date-picker-input-focus-border-color, #26a2e5);
          outline: none;
          z-index: 1;
        }

        &.is-invalid {
          border-color: var(--terra-date-picker-input-invalid-border-color, #e50000);
          box-shadow: var(--terra-date-picker-input-invalid-box-shadow, 0 0 0 1px #e50000);
          z-index: 1;

          &:focus {
            border-color: var(--terra-date-picker-input-invalid-focus-border-color, #e50000);
            box-shadow: var(--terra-date-picker-input-invalid-focus-box-shadow, 0 0 0 1px #e50000);
          }
        }

        &.is-incomplete {
          background-color: var(--terra-date-picker-input-incomplete-background-color, #ffc);
          
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) and (pointer: fine) {
            &:hover {
              background-color: var(--terra-date-picker-input-incomplete-hover-background-color, #ffc);
            }
          }

          &:focus {
            background-color: var(--terra-date-picker-input-incomplete-focus-background-color, #ffc);
          }

          &[disabled] {
            background-color: var(--terra-date-picker-input-incomplete-disabled-background-color, #ffc);
            border: var(--terra-date-picker-input-incomplete-disabled-border, 1px solid #868a8c);
            color: var(--terra-date-picker-input-incomplete-disabled-color, #1c1f21);
            opacity: var(--terra-date-picker-input-incomplete-disabled-opacity, 0.25);
          }
        }
      }

      .button {
        border: var(--terra-date-picker-button-border, 1px solid #dedfe0);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: var(--terra-date-picker-button-border-bottom-right-radius, 0.25em);
        border-top-left-radius: 0;
        border-top-right-radius: var(--terra-date-picker-button-border-top-right-radius, 0.25em);
        box-shadow: var(--terra-date-picker-button-box-shadow, none);
        color: var(--terra-date-picker-button-color, #1c1f21);
        margin-bottom: 0;
        margin-left: -0.071em;
        margin-top: 0;

        &:focus {
          border-color: var(--terra-date-picker-button-focus-border-color, #26a2e5);
          box-shadow: var(--terra-date-picker-button-focus-box-shadow, 0 0 1px 3px rgba(76, 178, 233, 0.5), 0 0 7px 4px rgba(76, 178, 233, 0.35));
          outline: none;
        }

        &.is-invalid {
          border-color: var(--terra-date-picker-button-invalid-border-color, #e50000);
          box-shadow: var(--terra-date-picker-button-invalid-box-shadow, 0 0 0 1px #e50000);

          &:focus {
            border-color: var(--terra-date-picker-button-invalid-focus-border-color, #e50000);
            box-shadow: var(--terra-date-picker-button-invalid-focus-box-shadow, 0 0 0 1px #e50000, 0 0 4px 3px rgba(229, 0, 0, 0.35));
            z-index: 2;
          }
        }
      }
    }

    .format-text {
      color: var(--terra-date-picker-format-text-color, rgba(0, 0, 0, 0.55));
      font-size: var(--terra-date-picker-format-text-font-size, 0.857rem);
      font-weight: var(--terra-date-picker-format-text-font-weight, normal);
      line-height: 1.25;
      margin-top: var(--terra-date-picker-format-text-margin-top, 0.357em);
      text-align: left;
    }
  }
}
/* stylelint-enable */
