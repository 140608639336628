// Themes
@import './clinical-lowlight-theme/ButtonGroup.module';
@import './orion-fusion-theme/ButtonGroup.module';

:local {
  .button-group {
    display: inline-flex;
    position: relative;
    vertical-align: middle;
    white-space: nowrap;
  }

  .button-group-button {
    background-color: var(--terra-button-group-button-background-color, #dedfe0);
    border-color: var(--terra-button-group-button-border-color, #c8cacb);
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
    box-shadow: var(--terra-button-group-button-box-shadow, none);
    color: var(--terra-button-group-button-color, #1c1f21);
    font-weight: var(--terra-button-group-button-font-weight, 400);
    margin: 0;
    position: relative;

    &.is-disabled,
    &:disabled {
      opacity: var(--terra-button-group-button-disabled-opacity, 0.3);
    }

    &.is-focused {
      background-color: var(--terra-button-group-button-focus-background-color, #dedfe0);
      border-color: var(--terra-button-group-button-focus-border-color, #c8cacb);
      box-shadow: var(--terra-button-group-button-focus-box-shadow, 0 0 1px 3px rgba(76, 178, 233, 0.5), 0 0 7px 4px rgba(76, 178, 233, 0.35));
      // A z-index needs to be added on focus so that the focus ring does not get hidden behind adjacent buttons
      z-index: 1000;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--terra-button-group-button-hover-background-color, #b9bbbc);
        color: var(--terra-button-group-button-hover-color, #000);
      }

      &:hover.is-selected {
        background-color: var(--terra-button-group-button-selected-and-hover-background-color, #40474a);
        border-color: var(--terra-button-group-button-selected-and-hover-border-color, #40474a);
        box-shadow: var(--terra-button-group-button-selected-and-hover-box-shadow);
        color: var(--terra-button-group-button-selected-and-hover-color, #fff);
      }
    }

    &.is-selected.is-focused {
      background-color: var(--terra-button-group-button-selected-and-focus-background-color, #52585b);
      border-color: var(--terra-button-group-button-selected-and-focus-border-color, #52585b);
      box-shadow: var(--terra-button-group-button-selected-and-focus-box-shadow, 0 0 1px 3px rgba(76, 178, 233, 0.5), 0 0 7px 4px rgba(76, 178, 233, 0.35));
      color: var(--terra-button-group-button-selected-and-focus-color, #fff);
    }

    &.is-selected {
      background-color: var(--terra-button-group-button-selected-background-color, #52585b);
      border-color: var(--terra-button-group-button-selected-border-color, #52585b);
      box-shadow: var(--terra-button-group-button-selected-box-shadow);
      color: var(--terra-button-group-button-selected-color, #fff);
    }

    &:active.is-selected,
    &:active {
      background-color: var(--terra-button-group-button-active-background-color, #52585b);
      border-color: var(--terra-button-group-button-active-border-color, #52585b);
      box-shadow: var(--terra-button-group-button-active-box-shadow);
      color: var(--terra-button-group-button-active-color, #fff);
    }
  }

  .button-group-button + .button-group-button {
    margin-left: -1px;
  }

  .button-group-button:first-child {
    border-bottom-left-radius: var(--terra-button-group-button-border-bottom-left-radius, 0.2142rem);
    border-top-left-radius: var(--terra-button-group-button-border-top-left-radius, 0.2142rem);
  }

  .button-group-button:last-child {
    border-bottom-right-radius: var(--terra-button-group-button-border-bottom-right-radius, 0.2142rem);
    border-top-right-radius: var(--terra-button-group-button-border-top-right-radius, 0.2142rem);
  }

  .is-block {
    width: 100%;

    .button-group-button {
      flex: 1 1 auto;
    }
  }
}
