// Themes
@import '../clinical-lowlight-theme/Menu.module';
@import '../orion-fusion-theme/Menu.module';

:local {
  .menu {
    list-style: none;
    margin: var(--terra-form-select-menu-margin, 0);
    outline: none;
    overflow: hidden;
    padding: var(--terra-form-select-menu-padding, 0 0 0.142857rem);
  }
}
