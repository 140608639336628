// import themes
@import './clinical-lowlight-theme/ActionHeaderContainer.module';
@import './orion-fusion-theme/ActionHeaderContainer.module';

:local {
  .flex-header {
    align-items: var(--terra-action-header-align-items, flex-start);
    background-color: var(--terra-action-header-background-color, #f9f9f9);
    border-bottom: var(--terra-action-header-border-bottom, 1px solid #d3d4d5);
    border-left: var(--terra-action-header-border-left);
    border-right: var(--terra-action-header-border-right);
    border-top: var(--terra-action-header-border-top);
    box-shadow: var(--terra-action-header-box-shadow);
    display: flex;
    justify-content: space-between;
    min-height: var(--terra-action-header-min-height, 2.92857rem);
    padding: var(--terra-action-header-padding, 0.35714rem 0.35714rem 0.35714rem 0);
    position: relative;
    width: 100%;

    .flex-collapse {
      align-self: var(--terra-action-header-flex-collapse-align-self);
      display: flex;
      flex: 0 0 auto;
      height: 100%;
      margin: var(--terra-action-header-flex-collapse-margin);
      max-width: 40%;
      position: relative;
    }
  }

  .flex-fill {
    align-self: var(--terra-action-header-flex-fill-align-self);
    flex: 1 1 auto;
    position: relative;
    width: 100%;
  }

  .flex-end {
    align-self: var(--terra-action-header-flex-end-align-self);
    display: flex;
    flex: 0 0 auto;
    position: relative;
  }

  .title-container {
    position: relative;
    width: 100%;
  }

  .title {
    color: var(--terra-action-header-color);
    font-size: var(--terra-action-header-font-size, 1.10714rem);
    font-weight: var(--terra-action-header-font-weight, 500);
    hyphens: auto;
    margin-bottom: 0;
    margin-top: 0;
    overflow-wrap: break-word; /* Modern browsers */
    padding: var(--terra-action-header-title-padding, 0.28571rem 0.71428rem 0 0.71428rem);
    padding-top: var(--terra-action-header-title-padding-top, 0.28571rem);
    width: 100%;
    word-wrap: break-word; /* For IE 10 and IE 11 */
  }

  /* stylelint-disable selector-max-compound-selectors */
  .flex-end + .flex-fill {
    .title {
      padding-left: var(--terra-action-header-buttons-plus-title-padding-left, 0.35714rem);
    }
  }
  /* stylelint-enable selector-max-compound-selectors */
}
