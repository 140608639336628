:local {
  // Arrange
  // --------------------------------------------------
  // Base arrange styles
  // Arranged grouping of related content such as an icon and heading

  // Vertically aligns arrange content to the top by default
  .arrange {
    display: flex;
  }

  // Makes sure fill expands to fill the remaining space of its parent container
  .fill {
    flex: 1 1 auto;
    min-width: 0;
  }

  // Styles specific to align items independently of each other
  .fit {
    align-self: flex-start;
    flex: 0 0 auto;
  }

  .center {
    align-self: center;
  }

  .bottom {
    align-self: flex-end;
  }

  .stretch {
    align-self: stretch;
  }

  .default {
    align-self: flex-start;
  }
}
