:local {
  .orion-fusion-theme {
    --terra-application-navigation-popup-content-background-color: #fff;
    --terra-application-navigation-popup-count-background-color: #fff3ce;
    --terra-application-navigation-popup-count-border: 1px solid #ffb20b;
    --terra-application-navigation-popup-count-color: #000;
    --terra-application-navigation-popup-item-active-background-color: #e1f3fd;
    --terra-application-navigation-popup-item-active-background-image: none;
    --terra-application-navigation-popup-item-active-box-shadow: inset 0 0 0 1px #fff;
    --terra-application-navigation-popup-item-color: #383f42;
    --terra-application-navigation-popup-item-focus-background-color: transparent;
    --terra-application-navigation-popup-item-focus-border-bottom: 1px solid #dedfe0;
    --terra-application-navigation-popup-item-focus-outline: 2px dashed #000;
    --terra-application-navigation-popup-item-focus-outline-offset: -2px;
    --terra-application-navigation-popup-item-hover-background-color: #f4fafe;
    --terra-application-navigation-popup-item-hover-background-image: linear-gradient(to bottom, #e1f2fd 2%, #ebf6fe 30%, #f4fafe 80%);
    --terra-application-navigation-popup-item-hover-box-shadow: inset 0 0 0 1px #fff;
    --terra-application-navigation-popup-item-icon-color: #0079be;
    --terra-application-navigation-popup-list-child-border-bottom: 1px solid #dedfe0;

    --terra-application-navigation-user-layout-border-bottom: 1px solid #dedfe0;
    --terra-application-navigation-user-layout-detail-color: rgba(28, 31, 33, 0.65);
    --terra-application-navigation-user-layout-name-color: #383f42;
  }
}
