:local {
  [dir=ltr],
  [dir=rtl] {
    .content {
      display: block;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      z-index: 7001;
    }
  }
}
