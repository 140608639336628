:local {
  .clinical-lowlight-theme {
    --terra-form-select-optgroup-margin: 0;
    --terra-form-select-optgroup-padding: 0;
    --terra-form-select-first-optgroup-label-padding: 0.38461538461538464rem;
    --terra-form-select-optgroup-label-border-top: 1px solid #141719;
    --terra-form-select-optgroup-label-margin: 0;
    --terra-form-select-optgroup-label-background-color: #141719;
    --terra-form-select-optgroup-label-color: #6f7477;
    --terra-form-select-optgroup-label-font-size: 0.9286rem;
    --terra-form-select-optgroup-label-font-style: normal;
    --terra-form-select-optgroup-label-font-weight: bold;
    --terra-form-select-optgroup-label-line-height: 1.23;
    --terra-form-select-optgroup-label-padding: 0.38461538461538464rem;
    --terra-form-select-optgroup-options-margin: 0;
    --terra-form-select-optgroup-options-padding: 0;
  }
}
