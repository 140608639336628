
// Themes
@import './clinical-lowlight-theme/ModalManager.module';
@import './orion-fusion-theme/ModalManager.module';

:local {
  .container {
    height: 100%;
  }

  .modal-manager {
    background-color: var(--terra-modal-manager-background-color, #fff);
    border: var(--terra-modal-manager-border);
    border-radius: var(--terra-modal-manager-border-radius, 5px);
    box-shadow: var(--terra-modal-manager-box-shadow, 0 3px 7px rgba(0, 0, 0, 0.3));
    color: var(--terra-modal-manager-foreground-color);

    // dimensions - heights
    &.height-240 {
      height: 240px;
    }

    &.height-420 {
      height: 420px;
    }

    &.height-600 {
      height: 600px;
    }

    &.height-690 {
      height: 690px;
    }

    &.height-780 {
      height: 780px;
    }

    &.height-870 {
      height: 870px;
    }

    &.height-960 {
      height: 960px;
    }

    &.height-1140 {
      height: 1140px;
    }

    // dimensions - widths
    &.width-320 {
      width: 320px;
    }
    
    &.width-480 {
      width: 480px;
    }

    &.width-560 {
      width: 560px;
    }

    &.width-640 {
      width: 640px;
    }

    &.width-800 {
      width: 800px;
    }

    &.width-960 {
      width: 960px;
    }

    &.width-1120 {
      width: 1120px;
    }

    &.width-1280 {
      width: 1280px;
    }

    &.width-1440 {
      width: 1440px;
    }

    &.width-1600 {
      width: 1600px;
    }

    &.width-1760 {
      width: 1760px;
    }

    &.width-1920 {
      width: 1920px;
    }

    @media screen and (max-width: 544) {
      &.height-690,
      &.height-780,
      &.height-870,
      &.height-870,
      &.height-960,
      &.height-1140 {
        height: 100%;
      }
    }
  }
}
