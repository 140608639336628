// Themes
@import '../clinical-lowlight-theme/HeaderCommon.module';
@import '../orion-fusion-theme/HeaderCommon.module';

:local {
  .compact-header {
    align-content: center;
    align-items: center;
    background-color: var(--terra-application-navigation-compact-header-background-color, #014979);
    background-image: var(--terra-application-navigation-compact-header-background-image, none);
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    min-height: 2.857rem;
    overflow: visible;
    padding-left: 0.3571rem;
    padding-right: 0.3571rem;
    position: relative;
    user-select: none;
    z-index: 5001;
  }

  .hidden-nav,
  .hidden-utils {
    clip: rect(0, 0, 0, 0);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    height: 0;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 0;

    &.is-open {
      background-color: var(--terra-application-navigation-compact-header-hidden-nav-background-color, #383f42);
      border: var(--terra-application-navigation-compact-header-hidden-nav-border, 2px solid #000);
      box-shadow: var(--terra-application-navigation-compact-header-hidden-nav-box-shadow, 0 0 20px -5px #000, inset 0 0 0 1px rgba(255, 255, 255, 0.5));
      box-sizing: border-box;
      clip: auto;
      font-family: inherit;
      font-size: 1rem;
      height: auto;
      left: 0;
      line-height: 1.5;
      max-height: 100%;
      overflow: auto;
      padding: 1rem 1.5rem;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 6001;
    }
  }

  .nav-inner,
  .hidden-utils {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .is-open .hidden-item {
    color: var(--terra-application-navigation-compact-header-hidden-nav-item-color, #fff);
    cursor: pointer;
    hyphens: auto;
    overflow-wrap: break-word; /* Modern browsers */
    padding: 0.28571rem;
    text-decoration: var(--terra-application-navigation-compact-header-hidden-nav-item-text-decoration, underline);
    width: 100%;
    word-wrap: break-word; /* For IE 10 and IE 11 */

    &:focus {
      outline: var(--terra-application-navigation-compact-header-hidden-nav-item-focus-outline, 2px dashed #fff);
      outline-offset: var(--terra-application-navigation-compact-header-hidden-nav-item-focus-outline-offset, -0.14285rem);
    }  
  }

  .menu-button {
    align-items: center;
    background-color: transparent;
    border: 0;
    color: var(--terra-application-navigation-compact-header-menu-button-color, rgba(255, 255, 255, 0.8));
    cursor: pointer;
    display: flex;
    flex: 0 0 auto;
    height: 2.143rem;
    justify-content: center;
    outline: none;
    padding: 0;
    position: relative;
    width: 2.143rem;

    > svg {
      height: 1rem;
      width: 1rem;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) and (pointer: fine) {
      &:hover {
        color: var(--terra-application-navigation-compact-header-menu-button-hover-color, rgba(255, 255, 255, 1));
      }
    }

    &:active {
      color: var(--terra-application-navigation-compact-header-menu-button-active-color, rgba(255, 255, 255, 0.5));
    }

    &:focus[data-focus-styles-enabled='true'] {
      background-color: var(--terra-application-navigation-compact-header-menu-button-focus-background-color, transparent);
      border-radius: 50%;
      color: var(--terra-application-navigation-compact-header-menu-button-focus-color, rgba(255, 255, 255, 0.8));
      outline: var(--terra-application-navigation-compact-header-menu-button-focus-outline, 2px dashed #fff);
      outline-offset: var(--terra-application-navigation-compact-header-menu-button-focus-outline-offset, -2px);
    }

    // Remove the inner border and padding in Firefox.
    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }    
  }

  .title-container {
    color: var(--terra-application-navigation-compact-header-title-color, rgba(255, 255, 255, 1));
    flex: 1 1 auto;
    margin-left: 0.3571rem;
    overflow: hidden;
    position: relative;
    user-select: none;

    .headline,
    .subline,
    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .headline,
    .subline {
      font-size: 0.8571rem;
    }

    .title {
      font-size: 1rem;
    }

    .title-element {
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
    }
  }

  .extensions-container {
    flex: 0 0 auto;
    margin-left: 1.429rem;
  }

  .skip-content-button {
    clip: rect(0, 0, 0, 0);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    height: 0;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 0;

    &:focus {
      background-color: var(--terra-application-navigation-compact-header-skip-focus-background-color, #383f42);
      border: var(--terra-application-navigation-compact-header-skip-focus-border, 2px solid #000);
      box-shadow: var(--terra-application-navigation-compact-header-skip-focus-box-shadow, 0 0 20px -5px #000, inset 0 0 0 1px rgba(255, 255, 255, 0.5));
      box-sizing: border-box;
      clip: auto;
      color: var(--terra-application-navigation-compact-header-skip-focus-color, #fff);
      font-family: inherit;
      font-size: 1rem;
      height: auto;
      left: 0;
      line-height: 1.28571;
      max-height: 100%;
      outline: var(--terra-application-navigation-compact-header-skip-focus-outline, 2px dashed #fff);
      outline-offset: var(--terra-application-navigation-compact-header-skip-focus-outline-offset, -0.71428rem);
      overflow: auto;
      padding: 0.64285rem 1.5rem;
      position: fixed;
      text-decoration: var(--terra-application-navigation-compact-header-skip-focus-text-decoration, underline);
      top: 0;
      width: var(--terra-application-navigation-compact-header-skip-focus-width, 100%);
      z-index: 20;
    }

    // Remove the inner border and padding in Firefox.
    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }
  }
}
