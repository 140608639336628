// Themes
@import '../clinical-lowlight-theme/UtilityMenuCommon.module';
@import '../orion-fusion-theme/UtilityMenuCommon.module';

:local {
  .utility-button {
    align-items: center;
    background-color: transparent;
    border-radius: 3px;
    border-width: 0;
    color: var(--terra-application-navigation-utility-header-button-color, rgba(255, 255, 255, 0.8));
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: space-between;
    line-height: 1.2;
    outline: 0;
    padding-bottom: 0.2143rem;
    padding-left: 0.2143rem;
    padding-right: 0.2143rem;
    padding-top: 0.2143rem;
    position: relative;
    width: 100%;

    &::-moz-focus-inner {
      border: 0;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) and (pointer: fine) {
      &:hover {
        color: var(--terra-application-navigation-utility-header-button-hover-color, #fff);
      }
    }

    &[data-focus-styles-enabled='true']:focus {
      background-color: var(--terra-application-navigation-utility-header-button-focus-background-color, transparent);
      color: var(--terra-application-navigation-utility-header-button-focus-color, rgba(255, 255, 255, 0.8));
      outline: var(--terra-application-navigation-utility-header-button-focus-outline, 2px dashed #fff);
      outline-offset: var(--terra-application-navigation-utility-header-button-focus-outline-offset, 3px);
    }

    &:active {
      color: var(--terra-application-navigation-utility-header-button-active-color, rgba(255, 255, 255, 0.5));
    }
  }

  .image {
    flex: 0 0 auto;
    font-size: 0.5714285714rem;
    height: 1.7143rem;
    margin-right: 0.3571rem;
    width: 1.7143rem;
  }

  .caret-icon {
    flex: 0 0 auto;
    height: 0.7143rem;
    margin-left: 0.2143rem;
    width: 0.7143rem;
  }

  .popup-anchor {
    flex: 0 0 auto;
    height: 100%;
    position: absolute;
    right: 0.2143rem;
    top: 0;
    width: 0.5714rem;
  }

  .title {
    flex: 1 1 auto;
    font-size: 1rem;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .no-user {
    border-radius: 100%;
    font-size: 1rem;
    line-height: 0;
    padding: 0.5714rem;
    position: relative;

    .no-user-image {
      height: 1rem;
      width: 1rem;
    }

    &[data-focus-styles-enabled='true']:focus {
      outline-offset: var(--terra-application-navigation-utility-header-button-no-user-focus-outline-offset, -2px);
    }
  }

  .image,
  .no-user-image {
    position: relative;
  }

  .image > *,
  .no-user-image > * {
    height: 100%;
    position: absolute;
    width: 100%;
  }
}
