:local {
  .visually-hidden-text {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap; /* Ensures words  are not read one at a time on screen readers*/
    width: 1px;
  }
}
