:local {
  .orion-fusion-theme {
    --terra-application-navigation-utility-header-button-active-color: rgba(255, 255, 255, 0.5);
    --terra-application-navigation-utility-header-button-color: rgba(255, 255, 255, 0.8);
    --terra-application-navigation-utility-header-button-focus-background-color: transparent;
    --terra-application-navigation-utility-header-button-focus-color: rgba(255, 255, 255, 0.8);
    --terra-application-navigation-utility-header-button-focus-outline: 2px dashed #fff;
    --terra-application-navigation-utility-header-button-focus-outline-offset: 3px;
    --terra-application-navigation-utility-header-button-hover-color: #fff;
    --terra-application-navigation-utility-header-button-no-user-focus-outline-offset: -2px;
  }
}
