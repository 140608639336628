:local {
  .clinical-lowlight-theme {
    --terra-popup-content-header-border-bottom: 1px solid #181b1d;
    --terra-popup-content-inner-background-color: #222a2e;
    --terra-popup-content-inner-border: 1px solid #181b1d;
    --terra-popup-content-inner-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.35), 0 4px 3px -3px rgba(0, 0, 0, 0.5);

    --terra-popup-content-header-background-color: #222a2e;
    --terra-popup-content-header-padding: 0;
    --terra-popup-content-close-icon-height: 1.25rem;
    --terra-popup-content-close-icon-margin-top: 0.125rem;
    --terra-popup-content-close-icon-width: 1.25rem;

    @include terra-inline-svg-var('--terra-popup-content-close-icon-background', '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path fill="#b2b5b6" d="M28.2 24L42.9 9.1 40.8 7l-1.7-1.6-.4-.5L24 19.7 9.4 4.9 7.2 7 5.6 8.6l-.5.5L19.8 24 5.1 38.9 7.2 41l1.7 1.6.5.5L24 28.3l14.7 14.8.4-.5 1.7-1.6 2.1-2.1L28.2 24z"/></svg>');
  }
}
