:local {
  .clinical-lowlight-theme {
    --terra-button-group-button-active-background-color: #1a2023;
    --terra-button-group-button-active-border-color: #181b1d;
    --terra-button-group-button-active-color: #9b9b9b;

    --terra-button-group-button-background-color: #2d3539;
    --terra-button-group-button-border-color: #181b1d;
    --terra-button-group-button-box-shadow: none;
    --terra-button-group-button-color: #b2b5b6;
    --terra-button-group-button-font-weight: 400;
    --terra-button-group-button-disabled-opacity: 0.3;

    --terra-button-group-button-focus-background-color: #1a2023;
    --terra-button-group-button-focus-border-color: #181b1d;
    --terra-button-group-button-focus-box-shadow: 0 0 1px 3px rgba(76, 178, 233, 0.5), 0 0 7px 4px rgba(76, 178, 233, 0.35);

    --terra-button-group-button-hover-background-color: #1e272b;
    --terra-button-group-button-hover-color: #9b9b9b;

    --terra-button-group-button-active-box-shadow: none;

    --terra-button-group-button-selected-background-color: #1a2023;
    --terra-button-group-button-selected-border-color: #181b1d;
    --terra-button-group-button-selected-box-shadow: none;
    --terra-button-group-button-selected-color: #9b9b9b;

    --terra-button-group-button-selected-and-focus-background-color: #1a2023;
    --terra-button-group-button-selected-and-focus-border-color: #181b1d;
    --terra-button-group-button-selected-and-focus-box-shadow: 0 0 1px 3px rgba(76, 178, 233, 0.5), 0 0 7px 4px rgba(76, 178, 233, 0.35);
    --terra-button-group-button-selected-and-focus-color: #9b9b9b;
    
    --terra-button-group-button-selected-and-hover-background-color: #121517;
    --terra-button-group-button-selected-and-hover-border-color: #181b1d;
    --terra-button-group-button-selected-and-hover-box-shadow: none;
    --terra-button-group-button-selected-and-hover-color: #9b9b9b;

    --terra-button-group-button-border-bottom-left-radius: 0.2142em;
    --terra-button-group-button-border-top-left-radius: 0.2142rem;
    --terra-button-group-button-border-bottom-right-radius: 0.2142em;
    --terra-button-group-button-border-top-right-radius: 0.2142rem;
  }
}
