:local {
  .orion-fusion-theme {
    --terra-action-footer-background-color: #f4f4f4;
    --terra-action-footer-border-top: 1px solid #9b9fa1;
    --terra-action-footer-empty-height: 0.9166666667rem;
    --terra-action-footer-with-actions-min-height: 3.25rem;
    --terra-action-footer-padding-bottom: 0.5rem;
    --terra-action-footer-padding-left: 0.5rem;
    --terra-action-footer-padding-right: 0.5rem;
    --terra-action-footer-padding-top: 0.5rem;
  }
}
