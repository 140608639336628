:local {
  .orion-fusion-theme {
    --terra-abstract-modal-vertical-inset: 10px;
    --terra-abstract-modal-horizontal-inset: 10px;
    --terra-abstract-modal-overlay-background-color: rgba(28, 31, 33, 0.8);
    --terra-abstract-modal-overlay-background-image: none;
    --terra-abstract-modal-overlay-foreground-color: none;
    --terra-abstract-modal-overlay-opacity: 1;
  }
}
