@import '~terra-mixins/lib/Mixins';

// Themes
@import './clinical-lowlight-theme/ListSectionHeader.module';
@import './orion-fusion-theme/ListSectionHeader.module';

// Section Header
// ========================================================
:local {
  .section-header {
    background-color: var(--terra-list-section-header-background-color, #e8e9ea);
    border-bottom: var(--terra-list-section-header-border-bottom, 1px solid #c8cacb);
    padding-bottom: var(--terra-list-section-header-padding-bottom, 0.5rem);
    padding-left: var(--terra-list-section-header-padding-left, 0.7142857143rem);
    padding-top: var(--terra-list-section-header-padding-top, 0.5rem);
    width: 100%;
  }

  .title {
    color: var(--terra-list-section-header-title-color, #1c1f21);
    font-size: var(--terra-list-section-header-title-font-size, 1rem);
    font-weight: var(--terra-list-section-header-title-font-weight, bold);
    line-height: var(--terra-list-section-header-title-line-height, 1.1428571429);
    margin: 0;
    // stylelint-disable-next-line plugin/no-unsupported-browser-features
    overflow-wrap: break-word; /* Modern browsers */
    padding: 0;
    width: 100%;
    word-wrap: break-word; /* For IE 10 and IE 11 */
  }

  .is-collapsible {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    outline: var(--terra-list-section-header-focus-outline, none);
    padding-left: var(--terra-list-section-header-collapsible-padding-left, 1rem);
    width: 100%;

    &[data-item-show-focus='true']:focus {
      background-color: var(--terra-list-section-header-focus-background-color, #dedfe0);
      box-shadow: var(--terra-list-section-header-focus-box-shadow, inset 0 0 0 1px #26a2e5, inset 0 0 0 4px rgba(76, 178, 233, 0.2));
      position: relative;
      z-index: 1;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) and (pointer: fine) {
      &.section-header:hover {
        background-color: var(--terra-list-section-header-hover-active-background-color, #d0d1d2);
      }
    }

    &.section-header:active {
      background-color: var(--terra-list-section-header-hover-active-background-color, #d0d1d2);
    }
  }

  .fill {
    flex: 1 1 auto;
    min-width: 0;
    width: 100%;
  }

  .start {
    flex: 0 0 auto;
    padding-right: var(--terra-list-section-header-start-padding-right, 0.7142857143rem);
  }

  .accordion-icon {
    background: var(--terra-list-section-header-accordion-icon-background, inline-svg('<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve"><title>0023_caretRight_a</title><path id="caretRight" d="M12,0l24,24L12,48V0z"/></svg>'));
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 0.8571428571rem;
    transform: rotate(0deg);
    width: 0.8571428571rem;

    // flips the icon about its vertical axis, presenting it in its mirrored-image form
    [dir=rtl] & {
      transform: scaleX(-1);
    }

    // tells the rtl postcss plugin to not transform this by default
    [dir] &.is-open {
      transform: rotate(90deg);
    }
  }
}
