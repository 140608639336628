:local {
  .clinical-lowlight-theme {
    --terra-overlay-light-background: rgba(28, 35, 39, 0.7);
    --terra-overlay-light-background-image: none;
    --terra-overlay-light-content-color: #c5c5c6;
    --terra-overlay-light-content-text-shadow: none;
    --terra-overlay-dark-background: rgba(28, 35, 39, 0.8);
    --terra-overlay-dark-background-image: none;
    --terra-overlay-dark-content-color: #c5c5c6;
    --terra-overlay-dark-content-text-shadow: none;
    --terra-overlay-content-color: #c5c5c6;
    --terra-overlay-content-padding-bottom: 1rem;
    --terra-overlay-content-padding-left: 1rem;
    --terra-overlay-content-padding-right: 1rem;
    --terra-overlay-content-padding-top: 1rem;
  }
}
