@import '~terra-mixins/lib/Mixins';
// Themes
@import './clinical-lowlight-theme/PopupContent.module';
@import './orion-fusion-theme/PopupContent.module';

:local {
  // These are relative to screen coordinates, so no bidi changes needed
  [dir=ltr],
  [dir=rtl] {
    .content:focus {
      outline: none;
    }

    .rounded-corners {
      border-radius: 3px;
    }

    .header {
      background-color: var(--terra-popup-content-header-background-color);
      border-bottom: var(--terra-popup-content-header-border-bottom, 1px solid #dedfe0);
      display: flex;
      justify-content: flex-end;
      padding: var(--terra-popup-content-header-padding);
      width: 100%;
    }

    .close-icon {
      background: var(--terra-popup-content-close-icon-background, inline-svg("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'><path fill='rgba(17, 17, 17, 1)' d='M28.2 24L42.9 9.1 40.8 7l-1.7-1.6-.4-.5L24 19.7 9.4 4.9 7.2 7 5.6 8.6l-.5.5L19.8 24 5.1 38.9 7.2 41l1.7 1.6.5.5L24 28.3l14.7 14.8.4-.5 1.7-1.6 2.1-2.1L28.2 24z'/></svg>"));
      display: inline-block;
      height: var(--terra-popup-content-close-icon-height, 1.25rem);
      margin-top: var(--terra-popup-content-close-icon-margin-top, 0.125rem);
      width: var(--terra-popup-content-close-icon-width, 1.25rem);
    }

    .inner {
      background-color: var(--terra-popup-content-inner-background-color, #fff);
      border: var(--terra-popup-content-inner-border, 1px solid #b2b5b6);
      box-shadow: var(--terra-popup-content-inner-box-shadow, 0 4px 8px 0 rgba(0, 0, 0, 0.35), 0 4px 3px -3px rgba(0, 0, 0, 0.5));
      max-height: 100vh;
      max-width: 100vw;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }

    .is-full-screen {
      box-shadow: none;
    }
  }
}
