:local {
  .clinical-lowlight-theme {
    --terra-popup-bottom-align-arrow-border-top-color: #181b1d;
    --terra-popup-bottom-align-arrow-after-border-top-color: #222a2e;
    --terra-popup-left-align-arrow-border-right-color: #181b1d;
    --terra-popup-left-align-arrow-after-border-right-color: #222a2e;
    --terra-popup-right-align-arrow-border-left-color: #181b1d;
    --terra-popup-right-align-arrow-after-border-left-color: #222a2e;
    --terra-popup-top-align-arrow-border-bottom-color: #181b1d;
    --terra-popup-top-align-arrow-after-border-bottom-color: #222a2e;
    --terra-popup-arrow-border-width: 11px;
    --terra-popup-arrow-after-border-width: 10px;
    --terra-popup-top-align-arrow-after-left: 10px;
    --terra-popup-top-align-arrow-after-top: 2px;
    --terra-popup-bottom-align-arrow-after-bottom: 2px;
    --terra-popup-bottom-align-arrow-after-left: 10px;
    --terra-popup-left-align-arrow-after-left: 2px;
    --terra-popup-left-align-arrow-after-top: 10px;
    --terra-popup-right-align-arrow-after-right: 2px;
    --terra-popup-right-align-arrow-after-top: 10px;
  }
}
