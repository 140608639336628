:local {
  .clinical-lowlight-theme {
    --terra-action-footer-background-color: transparent;
    --terra-action-footer-border-top: 0.07143rem solid #181b1d;
    --terra-action-footer-empty-height: 0.7142857143rem;
    --terra-action-footer-with-actions-min-height: 2.8571428571rem;
    --terra-action-footer-padding-bottom: 0.6428571429rem;
    --terra-action-footer-padding-left: 0.7142857143rem;
    --terra-action-footer-padding-right: 0.7142857143rem;
    --terra-action-footer-padding-top: 0.6428571429rem;
  }
}
