// Themes
@import '../clinical-lowlight-theme/ExtensionsCommon.module';
@import '../orion-fusion-theme/ExtensionsCommon.module';

/* stylelint-disable selector-class-pattern */
:local {
  .extension-rollup {
    align-items: center;
    color: var(--terra-application-navigation-extension-rollup-color, rgba(255, 255, 255, 0.8));
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    outline: none;
    position: relative;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none), (hover: hover) and (pointer: fine) {    
      &:hover {
        /* stylelint-disable-next-line max-nesting-depth */
        .extension-rollup-inner {
          color: var(--terra-application-navigation-extension-rollup-hover-color, #fff);
        }
      }
    }

    &[data-focus-styles-enabled='true']:focus {
      .extension-rollup-inner {
        background-color: var(--terra-application-navigation-extension-rollup-focus-background-color, transparent);
        border-radius: 100%;
        color: var(--terra-application-navigation-extension-rollup-focus-color, rgba(255, 255, 255, 0.8));
        outline: var(--terra-application-navigation-extension-rollup-focus-outline, 2px dashed #fff);
        outline-offset: var(--terra-application-navigation-extension-rollup-focus-outline-offset, -2px);
      }
    }
  
    &:active {
      .extension-rollup-inner {
        color: var(--terra-application-navigation-extension-rollup-active-color, rgba(255, 255, 255, 0.5));
      }
    }
  }

  .extension-rollup-inner {
    padding: 0.5714rem;
    position: relative;
  }

  .extension-rollup-image {
    height: 1rem;
    overflow: hidden;
    width: 1rem;
  }

  .extension-rollup-image > svg {
    vertical-align: top;
  }

  .extension-rollup-image,
  .extension-rollup-row-count {
    flex: 0 0  auto;
  }

  .extension-rollup-text {
    flex: 1 1 auto;
    width: 100%;
  }

  .extension-rollup-count {
    margin: 0;
    position: absolute;
    right: 0.07143rem;
    top: 0.07143rem;
  }
}
/* stylelint-enable selector-class-pattern */
