:local {
  .orion-fusion-theme {
    --terra-popup-content-header-background-color: #f4f4f4;
    --terra-popup-content-header-border-bottom: 0.16666667rem solid rgb(155, 159, 161);
    --terra-popup-content-header-padding: 0;
    --terra-popup-content-inner-background-color: #fff;
    --terra-popup-content-inner-border: 1px solid #b2b5b6;
    --terra-popup-content-inner-box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.35);
    --terra-popup-content-close-icon-height: 1.66667rem;
    --terra-popup-content-close-icon-margin-top: 0.28571rem;
    --terra-popup-content-close-icon-width: 1.66667rem;
    
    @include terra-inline-svg-var('--terra-popup-content-close-icon-background', '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path fill="currentColor" d="M4.2 48L0 43.8 43.8 0 48 4.2zM43.8 48L0 4.2 4.2 0 48 43.8z"/></svg>');
  }
}
