:local {
  .orion-fusion-theme {
    --terra-button-action-border-radius: 3px;
    --terra-button-border-radius: 3px;
    --terra-button-compact-min-height: 1.666666667rem;
    --terra-button-compact-padding-bottom: 0;
    --terra-button-compact-padding-top: 0;
    --terra-button-disabled-opacity: 0.3;
    --terra-button-font-size: 1rem;
    --terra-button-icon-first-margin-right: 0.41667rem;
    --terra-button-icon-height: 1rem;
    --terra-button-icon-only-margin-left: 0.5rem;
    --terra-button-icon-only-margin-right: 0.5rem;
    --terra-button-icon-width: 1rem;
    --terra-button-line-height: 1.5;
    --terra-button-min-height: 2.186116667rem;
    --terra-button-padding-bottom: 0.25rem;
    --terra-button-padding-top: 0.25rem;
    --terra-button-text-and-icon-padding-left: 0.83333rem;
    --terra-button-text-and-icon-padding-right: 0.83333rem;
    --terra-button-text-first-margin-right: 0.41667rem;
    --terra-button-text-only-padding-left: 0.83333rem;
    --terra-button-text-only-padding-right: 0.83333rem;
    --terra-button-utility-border-radius: 3px;

    // Neutral button
    --terra-button-active-and-focus-background-color-neutral: #9b9fa1;
    --terra-button-active-and-focus-border-color-neutral: #6f7477;
    --terra-button-active-and-focus-box-shadow-neutral: inset 0 3rem 1.5rem -2rem #f4f4f4, 0 0 1px 1px rgba(255, 255, 255, 0.65), 0 0 2px 3px rgba(76, 178, 233, 0.5), 0 0 6px 4px rgba(76, 178, 233, 0.3);
    --terra-button-active-background-color-neutral: #9b9fa1;
    --terra-button-active-border-color-neutral: #6f7477;
    --terra-button-active-color-neutral: #000;

    --terra-button-background-color-neutral: #dedfe0;
    --terra-button-border-color-neutral: #6f7477;
    --terra-button-box-shadow-neutral: inset 0 3rem 1.5rem -2rem #f4f4f4;
    --terra-button-color-neutral: #000;

    --terra-button-disabled-background-color-neutral: #dedfe0;
    --terra-button-disabled-border-color-neutral: #6f7477;
    --terra-button-disabled-box-shadow-neutral: inset 0 3rem 1.5rem -2rem #f4f4f4;
    --terra-button-disabled-color-neutral: #000;

    --terra-button-focus-background-color-neutral: #dedfe0;
    --terra-button-focus-border-color-neutral: #6f7477;
    --terra-button-focus-box-shadow-neutral: inset 0 3rem 1.5rem -2rem #f4f4f4, 0 0 1px 1px rgba(255, 255, 255, 0.65), 0 0 2px 3px rgba(76, 178, 233, 0.5), 0 0 6px 4px rgba(76, 178, 233, 0.3);

    --terra-button-font-weight-neutral: 400;

    --terra-button-hover-background-color-neutral: #b2b5b6;
    --terra-button-hover-border-color-neutral: #6f7477;
    --terra-button-hover-color-neutral: #000;

    // Emphasis button
    --terra-button-active-and-focus-background-color-emphasis: #26a2e5;
    --terra-button-active-and-focus-border-color-emphasis: #6f7477;
    --terra-button-active-and-focus-box-shadow-emphasis: inset 0 3rem 1.5rem -2rem #ebf6fd, 0 0 1px 1px rgba(255, 255, 255, 0.65), 0 0 2px 3px rgba(76, 178, 233, 0.5), 0 0 6px 4px rgba(76, 178, 233, 0.3);
    --terra-button-active-background-color-emphasis: #26a2e5;
    --terra-button-active-border-color-emphasis: #6f7477;
    --terra-button-active-color-emphasis: #000;

    --terra-button-background-color-emphasis: #a6d9f4;
    --terra-button-border-color-emphasis: #6f7477;
    --terra-button-box-shadow-emphasis: inset 0 3rem 1.5rem -2rem #ebf6fd;
    --terra-button-color-emphasis: #000;

    --terra-button-disabled-background-color-emphasis: #a6d9f4;
    --terra-button-disabled-border-color-emphasis: #6f7477;
    --terra-button-disabled-box-shadow-emphasis: inset 0 3rem 1.5rem -2rem #ebf6fd;
    --terra-button-disabled-color-emphasis: #000;

    --terra-button-focus-background-color-emphasis: #a6d9f4;
    --terra-button-focus-border-color-emphasis: #6f7477;
    --terra-button-focus-box-shadow-emphasis: inset 0 3rem 1.5rem -2rem #ebf6fd, 0 0 1px 1px rgba(255, 255, 255, 0.65), 0 0 2px 3px rgba(76, 178, 233, 0.5), 0 0 6px 4px rgba(76, 178, 233, 0.3);

    --terra-button-font-weight-emphasis: 400;

    --terra-button-hover-background-color-emphasis: #4cb2e9;
    --terra-button-hover-border-color-emphasis: #6f7477;
    --terra-button-hover-color-emphasis: #000;

    // Ghost Button
    --terra-button-active-and-focus-background-color-ghost: rgba(0, 0, 0, 0.2);
    --terra-button-active-and-focus-border-color-ghost: #6f7477;
    --terra-button-active-and-focus-box-shadow-ghost: 0 0 1px 1px rgba(255, 255, 255, 0.65), 0 0 2px 3px rgba(76, 178, 233, 0.5), 0 0 6px 4px rgba(76, 178, 233, 0.3);
    --terra-button-active-background-color-ghost: rgba(0, 0, 0, 0.2);
    --terra-button-active-border-color-ghost: #6f7477;
    --terra-button-active-color-ghost: #000;

    --terra-button-background-color-ghost: transparent;
    --terra-button-border-color-ghost: #6f7477;
    --terra-button-box-shadow-ghost: none;
    --terra-button-color-ghost: #000;

    --terra-button-disabled-background-color-ghost: transparent;
    --terra-button-disabled-border-color-ghost: #6f7477;
    --terra-button-disabled-box-shadow-ghost: none;
    --terra-button-disabled-color-ghost: #000;

    --terra-button-focus-background-color-ghost: transparent;
    --terra-button-focus-border-color-ghost: #6f7477;
    --terra-button-focus-box-shadow-ghost: 0 0 1px 1px rgba(255, 255, 255, 0.65), 0 0 2px 3px rgba(76, 178, 233, 0.5), 0 0 6px 4px rgba(76, 178, 233, 0.3);

    --terra-button-font-weight-ghost: 400;

    --terra-button-hover-background-color-ghost: rgba(0, 0, 0, 0.1);
    --terra-button-hover-border-color-ghost: #6f7477;
    --terra-button-hover-color-ghost: #000;

    // De-emphasis button
    --terra-button-active-and-focus-background-color-de-emphasis: transparent;
    --terra-button-active-and-focus-border-color-de-emphasis: transparent;
    --terra-button-active-and-focus-box-shadow-de-emphasis: 0 0 1px 1px rgba(255, 255, 255, 0.65), 0 0 2px 3px rgba(76, 178, 233, 0.5), 0 0 6px 4px rgba(76, 178, 233, 0.3);
    --terra-button-active-background-color-de-emphasis: transparent;
    --terra-button-active-border-color-de-emphasis: transparent;
    --terra-button-active-color-de-emphasis: #003f61;

    --terra-button-background-color-de-emphasis: transparent;
    --terra-button-border-color-de-emphasis: transparent;
    --terra-button-box-shadow-de-emphasis: none;
    --terra-button-color-de-emphasis: #0065a3;

    --terra-button-disabled-background-color-de-emphasis: transparent;
    --terra-button-disabled-border-color-de-emphasis: transparent;
    --terra-button-disabled-box-shadow-de-emphasis: none;
    --terra-button-disabled-color-de-emphasis: #0065a3;

    --terra-button-focus-background-color-de-emphasis: transparent;
    --terra-button-focus-border-color-de-emphasis: transparent;
    --terra-button-focus-box-shadow-de-emphasis: 0 0 1px 1px rgba(255, 255, 255, 0.65), 0 0 2px 3px rgba(76, 178, 233, 0.5), 0 0 6px 4px rgba(76, 178, 233, 0.3);

    --terra-button-font-weight-de-emphasis: 400;

    --terra-button-hover-background-color-de-emphasis: transparent;
    --terra-button-hover-border-color-de-emphasis: transparent;
    --terra-button-hover-color-de-emphasis: #003f61;

    // Action button
    --terra-button-active-and-focus-background-color-action: #26a2e5;
    --terra-button-active-and-focus-border-color-action: #6f7477;
    --terra-button-active-and-focus-box-shadow-action: inset 0 3rem 1.5rem -2rem #ebf6fd, 0 0 1px 1px rgba(255, 255, 255, 0.65), 0 0 2px 3px rgba(76, 178, 233, 0.5), 0 0 6px 4px rgba(76, 178, 233, 0.3);
    --terra-button-active-background-color-action: #26a2e5;
    --terra-button-active-border-color-action: #6f7477;
    --terra-button-active-color-action: #000;

    --terra-button-background-color-action: #a6d9f4;
    --terra-button-border-color-action: #6f7477;
    --terra-button-box-shadow-action: inset 0 3rem 1.5rem -2rem #ebf6fd;
    --terra-button-color-action: #000;

    --terra-button-disabled-background-color-action: #a6d9f4;
    --terra-button-disabled-border-color-action: #6f7477;
    --terra-button-disabled-box-shadow-action: inset 0 3rem 1.5rem -2rem #ebf6fd;
    --terra-button-disabled-color-action: #000;

    --terra-button-focus-background-color-action: #a6d9f4;
    --terra-button-focus-border-color-action: #6f7477;
    --terra-button-focus-box-shadow-action: inset 0 3rem 1.5rem -2rem #ebf6fd, 0 0 1px 1px rgba(255, 255, 255, 0.65), 0 0 2px 3px rgba(76, 178, 233, 0.5), 0 0 6px 4px rgba(76, 178, 233, 0.3);

    --terra-button-font-weight-action: 400;

    --terra-button-hover-background-color-action: #4cb2e9;
    --terra-button-hover-border-color-action: #6f7477;
    --terra-button-hover-color-action: #000;

    // Utility button
    --terra-button-active-and-focus-background-color-utility: transparent;
    --terra-button-active-and-focus-border-color-utility: transparent;
    --terra-button-active-and-focus-box-shadow-utility: 0 0 1px 1px rgba(255, 255, 255, 0.65), 0 0 2px 3px rgba(76, 178, 233, 0.5), 0 0 6px 4px rgba(76, 178, 233, 0.3);
    --terra-button-active-background-color-utility: transparent;
    --terra-button-active-border-color-utility: transparent;
    --terra-button-active-color-utility: #1c1f21;

    --terra-button-background-color-utility: transparent;
    --terra-button-border-color-utility: transparent;
    --terra-button-box-shadow-utility: none;
    --terra-button-color-utility: #1c1f21;

    --terra-button-disabled-background-color-utility: transparent;
    --terra-button-disabled-border-color-utility: transparent;
    --terra-button-disabled-box-shadow-utility: none;
    --terra-button-disabled-color-utility: #1c1f21;

    --terra-button-focus-background-color-utility: transparent;
    --terra-button-focus-border-color-utility: transparent;
    --terra-button-focus-box-shadow-utility: 0 0 1px 1px rgba(255, 255, 255, 0.65), 0 0 2px 3px rgba(76, 178, 233, 0.5), 0 0 6px 4px rgba(76, 178, 233, 0.3);

    --terra-button-font-weight-utility: 400;

    --terra-button-hover-background-color-utility: transparent;
    --terra-button-hover-border-color-utility: transparent;
    --terra-button-hover-color-utility: #004c76;
  }
}
