@import '~terra-mixins/lib/Mixins';

:local {
  .clinical-lowlight-theme {
    --terra-list-subsection-header-background-color: #141719;
    --terra-list-subsection-header-border-bottom: 1px solid #08090a;
    --terra-list-subsection-header-padding-bottom: 0.3571398571rem;
    --terra-list-subsection-header-padding-left: 1.2142857143rem;
    --terra-list-subsection-header-padding-top: 0.3571398571rem;
    --terra-list-subsection-header-title-color: #b2b5b6;
    --terra-list-subsection-header-title-font-size: 0.8571427571rem;
    --terra-list-subsection-header-title-font-weight: bold;
    --terra-list-subsection-header-title-line-height: 1.1666666667;
    --terra-list-subsection-header-focus-box-shadow: 0 0 1px 3px #004c76, 0 0 7px 4px #004c76;
    --terra-list-subsection-header-focus-outline: none;
    --terra-list-subsection-header-focus-background-color: #08090a;
    --terra-list-subsection-header-hover-active-background-color: #000;
    --terra-list-subsection-header-start-padding-right: 0.7142857143rem;
    --terra-list-subsection-header-collapsible-padding-left: 1rem;
    
    @include terra-inline-svg-var('--terra-list-subsection-header-accordion-icon-background','<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve"><title>0023_caretRight_a</title><path id="caretRight" fill="#b2b5b6" d="M12,0l24,24L12,48V0z"/></svg>'); 
  }
}
