@import '~terra-mixins/lib/Mixins';

:local {
  .clinical-lowlight-theme {
    --terra-status-view-padding-bottom: 0.714rem;
    --terra-status-view-padding-top: 0.714rem;
    --terra-status-view-title-font-size: 1.73rem;
    --terra-status-view-title-color: #4b5255;
    --terra-status-view-message-color: #6f7477;
    --terra-status-view-message-font-size: 1rem;
    --terra-status-view-message-padding-top: 0.714rem;
    --terra-status-view-divider-display: none;
    --terra-status-view-divider-padding-top: 1.429rem;
    --terra-status-view-actions-small-width-flex-direction: row;
    --terra-status-view-actions-padding-top: 0.71428rem;
    --terra-status-view-actions-button-flex: 0 1 auto;
    --terra-status-view-actions-button-margin-left: 0.357rem;
    --terra-status-view-actions-button-margin-right: 0.357rem;
    --terra-status-view-actions-button-margin-top: 0.71428rem;


    --terra-status-view-glyph-error-background: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2048%2048%27%3E%3Cpath%20fill%3D%27rgba%2875%2C%2082%2C%2085%2C%201%29%27%20d%3D%27M24%200c13.3%200%2024%2010.7%2024%2024S37.3%2048%2024%2048%200%2037.3%200%2024C0%2010.8%2010.7%200%2023.9%200h.1z%27%2F%3E%3Cpath%20fill%3D%27%23222a2e%27%20d%3D%27M7%2021.5h34v5H7v-5z%27%2F%3E%3C%2Fsvg%3E');
    --terra-status-view-glyph-no-data-background: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2048%2048%27%20class%3D%27is-bidi%27%3E%3Cpath%20fill%3D%27rgba%2875%2C%2082%2C%2085%2C%201%29%27%20d%3D%27M45.8%2012c1.2%200%202.2%201%202.2%202.2v26.7c0%201.2-1%202.2-2.2%202.2H2.2C1%2043%200%2042%200%2040.8V7.2C0%206%201%205%202.2%205H15c1.4.1%202.7.6%203.7%201.6l3.8%203.9c1%201%202.3%201.5%203.7%201.6l19.4-.1h.2z%27%2F%3E%3C%2Fsvg%3E');
    --terra-status-view-glyph-no-matching-results-background: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20data-name%3D%27Layer%201%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2048%2048%27%3E%3Cpath%20fill%3D%27rgba%2875%2C%2082%2C%2085%2C%201%29%27%20d%3D%27M47.4%2043.2l-13-13A19.66%2019.66%200%200%200%2038%2019a19%2019%200%201%200-7.9%2015.4l13%2013a2.05%202.05%200%200%200%202.9%200l1.4-1.4a1.93%201.93%200%200%200%200-2.8zM3%2019a16%2016%200%201%201%2016%2016A16%2016%200%200%201%203%2019z%27%2F%3E%3C%2Fsvg%3E');
    --terra-status-view-glyph-not-authorized-background: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20data-name%3D%27Layer%201%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20viewBox%3D%270%200%2048%2048%27%3E%3Cpath%20fill%3D%27rgba%2875%2C%2082%2C%2085%2C%201%29%27%20d%3D%27M38.8%2020H35v-9a11%2011%200%200%200-22%200v9H9.2A2.22%202.22%200%200%200%207%2022.2v23.7A2.2%202.2%200%200%200%209.2%2048h29.7a2.22%202.22%200%200%200%202.2-2.2V22.2a2.38%202.38%200%200%200-2.3-2.2zM26%2033.5V40h-4v-6.5a3.26%203.26%200%200%201-1-2.3%203%203%200%200%201%203-3%203.08%203.08%200%200%201%203%203%203.26%203.26%200%200%201-1%202.3zm6-14.9V20H16v-9a8%208%200%200%201%2016%200z%27%2F%3E%3C%2Fsvg%3E');
  }
}
