:local {
  .clinical-lowlight-theme {
    --terra-application-navigation-popup-content-background-color: #222a2e;
    --terra-application-navigation-popup-count-background-color: #ffb20b;
    --terra-application-navigation-popup-count-border: 1px solid #ffb20b;
    --terra-application-navigation-popup-count-color: #ffb20b;
    --terra-application-navigation-popup-item-active-background-color: #1c2326;
    --terra-application-navigation-popup-item-active-background-image: none;
    --terra-application-navigation-popup-item-active-box-shadow: inset 0 0 0 1px #3496cf;
    --terra-application-navigation-popup-item-color: #b2b5b6;
    --terra-application-navigation-popup-item-focus-background-color: transparent;
    --terra-application-navigation-popup-item-focus-border-bottom: 1px solid #181b1d;
    --terra-application-navigation-popup-item-focus-outline: 2px dashed #3496cf;
    --terra-application-navigation-popup-item-focus-outline-offset: -2px;
    --terra-application-navigation-popup-item-hover-background-color: #1c2326;
    --terra-application-navigation-popup-item-hover-background-image: none;
    --terra-application-navigation-popup-item-hover-box-shadow: inset 0 0 0 1px #3496cf;
    --terra-application-navigation-popup-item-icon-color: #c5c5c6;
    --terra-application-navigation-popup-list-child-border-bottom: 1px solid #181b1d;

    --terra-application-navigation-user-layout-border-bottom: 1px solid #181b1d;
    --terra-application-navigation-user-layout-detail-color: #3496cf;
    --terra-application-navigation-user-layout-name-color: #b2b5b6;
  }
}
