:local {
  .clinical-lowlight-theme {
    --terra-avatar-background-clip: padding-box;
    --terra-avatar-border: none;
    --terra-avatar-color: #404344;
    --terra-avatar-height: 3.286em;
    --terra-avatar-width: 3.286em;
    --terra-avatar-after-box-shadow: none;
    --terra-avatar-initials-font-size: 1.429em;
    --terra-avatar-initials-font-weight: 400;
    --terra-avatar-neutral-background-color: #6f7477;
    --terra-avatar-neutral-after-box-shadow: none;
    --terra-avatar-one-background-color: #6f7477;
    --terra-avatar-one-after-box-shadow: none;
    --terra-avatar-two-background-color: #6f7477;
    --terra-avatar-two-after-box-shadow: none;
    --terra-avatar-three-background-color: #6f7477;
    --terra-avatar-three-after-box-shadow: none;
    --terra-avatar-four-background-color: #6f7477;
    --terra-avatar-four-after-box-shadow: none;
    --terra-avatar-five-background-color: #6f7477;
    --terra-avatar-five-after-box-shadow: none;
    --terra-avatar-six-background-color: #6f7477;
    --terra-avatar-six-after-box-shadow: none;
    --terra-avatar-seven-background-color: #6f7477;
    --terra-avatar-seven-after-box-shadow: none;
    --terra-avatar-eight-background-color: #6f7477;
    --terra-avatar-eight-after-box-shadow: none;
    --terra-avatar-nine-background-color: #6f7477;
    --terra-avatar-nine-after-box-shadow: none;
    --terra-avatar-ten-background-color: #6f7477;
    --terra-avatar-ten-after-box-shadow: none;
    --terra-avatar-image-is-deceased-opacity: 0.5;
    --terra-avatar-is-deceased-background-color: #6f7477;
    --terra-avatar-is-deceased-after-box-shadow: none;

    @include terra-inline-svg-var('--terra-avatar-icon-facility-background-image', "<svg data-name='Layer_1' xmlns='http://www.w3.org/2000/svg' fill='#404344' viewBox='0 0 48 48'><path d='M33,20.2V12H15v8.2H9.8V48h12v-9h4.5v9h12V20.2H33z M17.2,42.8h-3V39h3V42.8z M17.2,33.8h-3V30h3V33.8z M25.5,33.8h-3V30h3 V33.8z M29.2,22.5h-3.8v3.8h-3v-3.8h-3.8v-3h3.8v-3.8h3v3.8h3.8V22.5z M33.8,42.8h-3V39h3V42.8z M33.8,33.8h-3V30h3V33.8z'/></svg>");
    @include terra-inline-svg-var('--terra-avatar-icon-shared-user-background-image', "<svg data-name='Layer_1' xmlns='http://www.w3.org/2000/svg' fill='#404344' viewBox='0 0 48 48'><path d='M24,8.6c5.1,0,9.3,4.2,9.3,9.3s-4.2,9.3-9.3,9.3s-9.3-4.2-9.3-9.3C14.8,12.8,18.9,8.6,24,8.6z M40,48v-4.7 c0-7.6-6.1-13.8-13.7-13.8c0,0,0,0,0,0h-4.5C14.1,29.6,8,35.7,8,43.3c0,0,0,0,0,0V48H40z'/></svg>");
    @include terra-inline-svg-var('--terra-avatar-icon-user-background-image', "<svg data-name='Layer_1' xmlns='http://www.w3.org/2000/svg' fill='#404344' viewBox='0 0 48 48'><path d='M24,8.6c5.1,0,9.3,4.2,9.3,9.3s-4.2,9.3-9.3,9.3s-9.3-4.2-9.3-9.3C14.8,12.8,18.9,8.6,24,8.6z M40,48v-4.7 c0-7.6-6.1-13.8-13.7-13.8c0,0,0,0,0,0h-4.5C14.1,29.6,8,35.7,8,43.3c0,0,0,0,0,0V48H40z'/></svg>");
    @include terra-inline-svg-var('--terra-avatar-icon-provider-background-image',"<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'><path fill='#fff' d='M24 28.9c4.7 0 8.5-3.8 8.5-8.5s-3.8-8.5-8.5-8.5-8.5 3.8-8.5 8.5 3.8 8.5 8.5 8.5zm6 2.9v4.4c0 .3.2.9.9 1.6.6.5.6 1.3.6 1.3v4.5c0 .4-.3.8-.8.8-.4 0-.8-.3-.8-.8v-5.2h-1.5v5.2c0 .4-.3.8-.8.8-.4 0-.8-.3-.8-.8V39s0-.8.6-1.3c.8-.6.9-1.2.9-1.6v-4.7c-.8-.2-1.6-.2-2.4-.2h-4.1c-.8 0-1.6.1-2.4.2v6.9c1.3.3 2.2 1.5 2.2 2.9 0 1.7-1.3 3-3 3s-3-1.3-3-3c0-1.4 1-2.6 2.2-2.9v-6.5c-5 1.7-8.6 6.4-8.6 11.9V48h29.3v-4.3c.1-5.5-3.5-10.2-8.5-11.9zm-12.8 9.4c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5-.7-1.5-1.5-1.5-1.5.7-1.5 1.5z'/></svg>");
  }
}
