// Themes
@import './clinical-lowlight-theme/Spacer.module';
@import './orion-fusion-theme/Spacer.module';

$small-minus-2: 0.2142857143rem;
$small-minus-1: 0.3571428571rem;
$small: 0.5rem;
$medium: 0.7142857143rem;
$large: 0.8571428571rem;
$large-plus-1: 1.0714285714rem;
$large-plus-2: 1.4285714286rem;
$large-plus-3: 2.1428571429rem;
$large-plus-4: 3.5714285714rem;

/* stylelint-disable terra/custom-property-name*/
:local {
  .margin-top-none {
    margin-top: 0;
  }

  .margin-top-small-minus-2 {
    margin-top: var(--terra-spacer-small-minus-2, $small-minus-2); // 3px
  }

  .margin-top-small-minus-1 {
    margin-top: var(--terra-spacer-small-minus-1, $small-minus-1); // 5px
  }

  .margin-top-small {
    margin-top: var(--terra-spacer-small, $small); // 7px
  }

  .margin-top-medium {
    margin-top: var(--terra-spacer-medium, $medium); // 10px
  }

  .margin-top-large {
    margin-top: var(--terra-spacer-large, $large); // 12px
  }

  .margin-top-large-plus-1 {
    margin-top: var(--terra-spacer-large-plus-1, $large-plus-1); // 15px
  }

  .margin-top-large-plus-2 {
    margin-top: var(--terra-spacer-large-plus-2, $large-plus-2); // 20px
  }

  .margin-top-large-plus-3 {
    margin-top: var(--terra-spacer-large-plus-3, $large-plus-3); // 30px
  }

  .margin-top-large-plus-4 {
    margin-top: var(--terra-spacer-large-plus-4, $large-plus-4); // 50px
  }

  .margin-bottom-none {
    margin-bottom: 0;
  }

  .margin-bottom-small-minus-2 {
    margin-bottom: var(--terra-spacer-small-minus-2, $small-minus-2); // 3px
  }

  .margin-bottom-small-minus-1 {
    margin-bottom: var(--terra-spacer-small-minus-1, $small-minus-1); // 5px
  }

  .margin-bottom-small {
    margin-bottom: var(--terra-spacer-small, $small); // 7px
  }

  .margin-bottom-medium {
    margin-bottom: var(--terra-spacer-medium, $medium); // 10px
  }

  .margin-bottom-large {
    margin-bottom: var(--terra-spacer-large, $large); // 12px
  }

  .margin-bottom-large-plus-1 {
    margin-bottom: var(--terra-spacer-large-plus-1, $large-plus-1); // 15px
  }

  .margin-bottom-large-plus-2 {
    margin-bottom: var(--terra-spacer-large-plus-2, $large-plus-2); // 20px
  }

  .margin-bottom-large-plus-3 {
    margin-bottom: var(--terra-spacer-large-plus-3, $large-plus-3); // 30px
  }

  .margin-bottom-large-plus-4 {
    margin-bottom: var(--terra-spacer-large-plus-4, $large-plus-4); // 50px
  }

  .margin-left-none {
    margin-left: 0;
  }

  .margin-left-small-minus-2 {
    margin-left: var(--terra-spacer-small-minus-2, $small-minus-2); // 3px
  }

  .margin-left-small-minus-1 {
    margin-left: var(--terra-spacer-small-minus-1, $small-minus-1); // 5px
  }

  .margin-left-small {
    margin-left: var(--terra-spacer-small, $small); // 7px
  }

  .margin-left-medium {
    margin-left: var(--terra-spacer-medium, $medium); // 10px
  }

  .margin-left-large {
    margin-left: var(--terra-spacer-large, $large); // 12px
  }

  .margin-left-large-plus-1 {
    margin-left: var(--terra-spacer-large-plus-1, $large-plus-1); // 15px
  }

  .margin-left-large-plus-2 {
    margin-left: var(--terra-spacer-large-plus-2, $large-plus-2); // 20px
  }

  .margin-left-large-plus-3 {
    margin-left: var(--terra-spacer-large-plus-3, $large-plus-3); // 30px
  }

  .margin-left-large-plus-4 {
    margin-left: var(--terra-spacer-large-plus-4, $large-plus-4); // 50px
  }

  .margin-right-none {
    margin-right: 0;
  }

  .margin-right-small-minus-2 {
    margin-right: var(--terra-spacer-small-minus-2, $small-minus-2); // 3px
  }

  .margin-right-small-minus-1 {
    margin-right: var(--terra-spacer-small-minus-1, $small-minus-1); // 5px
  }

  .margin-right-small {
    margin-right: var(--terra-spacer-small, $small); // 7px
  }

  .margin-right-medium {
    margin-right: var(--terra-spacer-medium, $medium); // 10px
  }

  .margin-right-large {
    margin-right: var(--terra-spacer-large, $large); // 12px
  }

  .margin-right-large-plus-1 {
    margin-right: var(--terra-spacer-large-plus-1, $large-plus-1); // 15px
  }

  .margin-right-large-plus-2 {
    margin-right: var(--terra-spacer-large-plus-2, $large-plus-2); // 20px
  }

  .margin-right-large-plus-3 {
    margin-right: var(--terra-spacer-large-plus-3, $large-plus-3); // 30px
  }

  .margin-right-large-plus-4 {
    margin-right: var(--terra-spacer-large-plus-4, $large-plus-4); // 50px
  }

  .padding-top-none {
    padding-top: 0;
  }

  .padding-top-small-minus-2 {
    padding-top: var(--terra-spacer-small-minus-2, $small-minus-2); // 3px
  }

  .padding-top-small-minus-1 {
    padding-top: var(--terra-spacer-small-minus-1, $small-minus-1); // 5px
  }

  .padding-top-small {
    padding-top: var(--terra-spacer-small, $small); // 7px
  }

  .padding-top-medium {
    padding-top: var(--terra-spacer-medium, $medium); // 10px
  }

  .padding-top-large {
    padding-top: var(--terra-spacer-large, $large); // 12px
  }

  .padding-top-large-plus-1 {
    padding-top: var(--terra-spacer-large-plus-1, $large-plus-1); // 15px
  }

  .padding-top-large-plus-2 {
    padding-top: var(--terra-spacer-large-plus-2, $large-plus-2); // 20px
  }

  .padding-top-large-plus-3 {
    padding-top: var(--terra-spacer-large-plus-3, $large-plus-3); // 30px
  }

  .padding-top-large-plus-4 {
    padding-top: var(--terra-spacer-large-plus-4, $large-plus-4); // 50px
  }

  .padding-bottom-none {
    padding-bottom: 0;
  }

  .padding-bottom-small-minus-2 {
    padding-bottom: var(--terra-spacer-small-minus-2, $small-minus-2); // 3px
  }

  .padding-bottom-small-minus-1 {
    padding-bottom: var(--terra-spacer-small-minus-1, $small-minus-1); // 5px
  }

  .padding-bottom-small {
    padding-bottom: var(--terra-spacer-small, $small); // 7px
  }

  .padding-bottom-medium {
    padding-bottom: var(--terra-spacer-medium, $medium); // 10px
  }

  .padding-bottom-large {
    padding-bottom: var(--terra-spacer-large, $large); // 12px
  }

  .padding-bottom-large-plus-1 {
    padding-bottom: var(--terra-spacer-large-plus-1, $large-plus-1); // 15px
  }

  .padding-bottom-large-plus-2 {
    padding-bottom: var(--terra-spacer-large-plus-2, $large-plus-2); // 20px
  }

  .padding-bottom-large-plus-3 {
    padding-bottom: var(--terra-spacer-large-plus-3, $large-plus-3); // 30px
  }

  .padding-bottom-large-plus-4 {
    padding-bottom: var(--terra-spacer-large-plus-4, $large-plus-4); // 50px
  }

  .padding-left-none {
    padding-left: 0;
  }

  .padding-left-small-minus-2 {
    padding-left: var(--terra-spacer-small-minus-2, $small-minus-2); // 3px
  }

  .padding-left-small-minus-1 {
    padding-left: var(--terra-spacer-small-minus-1, $small-minus-1); // 5px
  }

  .padding-left-small {
    padding-left: var(--terra-spacer-small, $small); // 7px
  }

  .padding-left-medium {
    padding-left: var(--terra-spacer-medium, $medium); // 10px
  }

  .padding-left-large {
    padding-left: var(--terra-spacer-large, $large); // 12px
  }

  .padding-left-large-plus-1 {
    padding-left: var(--terra-spacer-large-plus-1, $large-plus-1); // 15px
  }

  .padding-left-large-plus-2 {
    padding-left: var(--terra-spacer-large-plus-2, $large-plus-2); // 20px
  }

  .padding-left-large-plus-3 {
    padding-left: var(--terra-spacer-large-plus-3, $large-plus-3); // 30px
  }

  .padding-left-large-plus-4 {
    padding-left: var(--terra-spacer-large-plus-4, $large-plus-4); // 50px
  }

  .padding-right-none {
    padding-right: 0;
  }

  .padding-right-small-minus-2 {
    padding-right: var(--terra-spacer-small-minus-2, $small-minus-2); // 3px
  }

  .padding-right-small-minus-1 {
    padding-right: var(--terra-spacer-small-minus-1, $small-minus-1); // 5px
  }

  .padding-right-small {
    padding-right: var(--terra-spacer-small, $small); // 7px
  }

  .padding-right-medium {
    padding-right: var(--terra-spacer-medium, $medium); // 10px
  }

  .padding-right-large {
    padding-right: var(--terra-spacer-large, $large); // 12px
  }

  .padding-right-large-plus-1 {
    padding-right: var(--terra-spacer-large-plus-1, $large-plus-1); // 15px
  }

  .padding-right-large-plus-2 {
    padding-right: var(--terra-spacer-large-plus-2, $large-plus-2); // 20px
  }

  .padding-right-large-plus-3 {
    padding-right: var(--terra-spacer-large-plus-3, $large-plus-3); // 30px
  }

  .padding-right-large-plus-4 {
    padding-right: var(--terra-spacer-large-plus-4, $large-plus-4); // 50px
  }

  .is-inline-block {
    display: inline-block;
  }
}
/* stylelint-enable terra/custom-property-name*/
