:local {
  .clinical-lowlight-theme {
    --terra-divider-border-color: #181b1d;
    --terra-divider-border-top-width: 1px;
    --terra-divider-text-color: #b2b5b6;
    --terra-divider-text-font-size: 0.92857rem;
    --terra-divider-text-font-style: normal;
    --terra-divider-text-font-weight: bold;
    --terra-divider-text-line-height: 1.231;
    --terra-divider-text-max-width: 80em;
    --terra-divider-text-padding-left: 0.7142857143rem;
    --terra-divider-text-padding-right: 0.7142857143rem;
  }
}
