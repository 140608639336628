@import '~terra-mixins/lib/Mixins';

:local {
  .orion-fusion-theme {
    --terra-form-select-tag-background: #f2f2f2;
    --terra-form-select-tag-border-radius: 3px;
    --terra-form-select-tag-font-size: 1rem;
    --terra-form-select-tag-height: auto;
    --terra-form-select-tag-line-height: normal;
    --terra-form-select-tag-margin-right: 0.5rem;
    --terra-form-select-tag-margin-top: 0.25rem;
    --terra-form-select-tag-display-border-bottom: 1px solid #dedfe0;
    --terra-form-select-tag-display-color: #1c1f21;
    --terra-form-select-tag-display-padding: 0.16667rem 0.33333rem;
    --terra-form-select-tag-deselect-background: #dedfe0;
    --terra-form-select-tag-deselect-border-bottom: 1px solid #dedfe0;
    --terra-form-select-tag-deselect-font-size: 1rem;
    --terra-form-select-tag-deselect-padding: 0.16667rem 0.33333rem;
    --terra-form-select-tag-deselect-hover-background: #b9bbbc;
    --terra-form-select-tag-deselect-hover-border-bottom: 1px solid #dedfe0;
    --terra-form-select-tag-icon-height: 0.91667rem;
    --terra-form-select-tag-icon-width: 0.91667rem;

    @include terra-inline-svg-var('--terra-form-select-tag-icon-background', '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M4.2 48L0 43.8 43.8 0 48 4.2zM43.8 48L0 4.2 4.2 0 48 43.8z"/></svg>');
  }
}
