:global {
  .orion-fusion-theme {
    --terra-base-background-attachment: scroll;
    --terra-base-background-color: rgba(255, 255, 255, 1);
    --terra-base-background-image: none;
    --terra-base-background-position: none;
    --terra-base-background-repeat: none;
    --terra-base-background-size: auto;
    --terra-base-font-family: Tahoma, Geneva, Verdana, Trebuchet MS, sans-serif;
    --terra-base-font-size: 75%;            // root: 16px, base: 14px
    --terra-base-html-height: 100%;
    --terra-base-html-margin: 0;
    --terra-base-color: #1c1f21;
    --terra-base-body-height: 100%;
    --terra-base-line-height: 1.4285694285714286;
    --terra-base-body-margin: 0;
  }
}
