:local {
  .orion-fusion-theme {
    --terra-spacer-small-minus-2: 0.1666666666rem;
    --terra-spacer-small-minus-1: 0.25rem;
    --terra-spacer-small: 0.25rem;
    --terra-spacer-medium: 0.5rem;
    --terra-spacer-large: 0.75rem;
    --terra-spacer-large-plus-1: 1rem;
    --terra-spacer-large-plus-2: 1.25rem;
    --terra-spacer-large-plus-3: 2rem;
    --terra-spacer-large-plus-4: 3rem;
  }
}
