// Themes
@import './clinical-lowlight-theme/Image.module';
@import './orion-fusion-theme/Image.module';

:local {
  .image {
    border: 0;
    display: inline-block;
    margin: 0;
    padding: 0;
    vertical-align: middle;
  }

  .rounded {
    border-radius: var(--terra-image-rounded-border-radius, 0.3rem);
  }

  .circle {
    border-radius: var(--terra-image-circle-border-radius, 50%);
  }

  .thumbnail {
    background-color: var(--terra-image-thumbnail-background-color, #fff);
    border-color: var(--terra-image-thumbnail-border-color, #ddd);
    border-radius: var(--terra-image-thumbnail-border-radius, 0.25em);
    border-style: var(--terra-image-thumbnail-border-style, solid);
    border-width: var(--terra-image-thumbnail-border-width, 1px);
    padding-bottom: var(--terra-image-thumbnail-padding-bottom, 0.25rem);
    padding-left: var(--terra-image-thumbnail-padding-left, 0.25rem);
    padding-right: var(--terra-image-thumbnail-padding-right, 0.25rem);
    padding-top: var(--terra-image-thumbnail-padding-top, 0.25rem);
  }

  .fluid {
    height: auto;
    max-height: 100%;
    max-width: 100%;
  }

  .hidden {
    display: none;
  }

  /**
   * Polyfill 'object-fit-images' for IE requires to use object-fit inside font-family.
   */
  .contain {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif, 'object-fit: contain;';
    object-fit: contain;
  }

  .cover {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif, 'object-fit: cover;';
    object-fit: cover;
  }

  .fill {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif, 'object-fit: fill;';
    object-fit: fill;
  }

  .scale-down {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif, 'object-fit: scale-down;';
    object-fit: scale-down;
  }

  .none {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif, 'object-fit: none;';
    object-fit: none;
  }
}
