:local {
  .clinical-lowlight-theme {
    --terra-hyperlink-color: #007ee0;
    --terra-hyperlink-text-decoration: none;
    --terra-hyperlink-visited-color: #007ee0;
    --terra-hyperlink-visited-focus-color: #007ee0;
    --terra-hyperlink-hover-background-color: transparent;
    --terra-hyperlink-hover-color: #007ee0;
    --terra-hyperlink-hover-text-decoration: underline;
    --terra-hyperlink-focus-background-color: transparent;
    --terra-hyperlink-focus-border-radius: 0.2142rem;
    --terra-hyperlink-focus-box-shadow: 0 0 1px 3px #004c76, 0 0 7px 4px #004c76;
    --terra-hyperlink-focus-color: #007ee0;
    --terra-hyperlink-focus-text-decoration: underline;
    --terra-hyperlink-active-color: #007ee0;
    --terra-hyperlink-disabled-color: rgba(212, 222, 225, 0.15);
    --terra-hyperlink-disabled-text-decoration: none;
    --terra-hyperlink-underline-hidden-color: #007ee0;
    --terra-hyperlink-underline-hidden-focus-color: #007ee0;
    --terra-hyperlink-underline-hidden-hover-color: #007ee0;
    --terra-hyperlink-underline-hidden-visited-color: #007ee0;
    --terra-hyperlink-underline-hidden-visited-focus-color: #007ee0;
    --terra-hyperlink-icon-margin-left: 0.5em;
  }
}
