:local {
  .clinical-lowlight-theme {
    --terra-application-navigation-tab-active-background-color: #034370;
    --terra-application-navigation-tab-active-color: #b2b5b6;
    --terra-application-navigation-tab-background-color: transparent;
    --terra-application-navigation-tab-box-shadow: inset 1px 0 0 0 #022b36, inset 2px 0 0 0 #0f699f;
    --terra-application-navigation-tab-color: #b2b5b6;
    --terra-application-navigation-tab-count-background-image: linear-gradient(#ffb20b, #ffc20a);
    --terra-application-navigation-tab-count-border: none;
    --terra-application-navigation-tab-count-box-shadow: 0 2px 0 0 rgba(3, 43, 70, 0.8), 0 3px 5px 1px rgba(0, 0, 0, 0.26);
    --terra-application-navigation-tab-count-color: #000;
    --terra-application-navigation-tab-current-box-shadow: inset 1px 0 0 0 #0f699f, inset 2px 0 0 0 #022b36, inset -1px 0 0 0 #022b36, inset -2px 0 0 0 #0f699f;
    --terra-application-navigation-tab-current-color: #b2b5b6;
    --terra-application-navigation-tab-current-inner-background-color: #034370;
    --terra-application-navigation-tab-current-inner-background-image: linear-gradient(#3496cf, #3496cf);
    --terra-application-navigation-tab-current-inner-background-size: 100% 5px;
    --terra-application-navigation-tab-current-last-box-shadow: inset 1px 0 0 0 #022b36, inset 2px 0 0 0 #0f699f, inset -1px 0 0 0 #022b36, inset -2px 0 0 0 #0f699f;
    --terra-application-navigation-tab-focus-background-color: transparent;
    --terra-application-navigation-tab-focus-outline: 2px dashed #3496cf;
    --terra-application-navigation-tab-focus-outline-offset: -5px;
    --terra-application-navigation-tab-hover-background-color: #034370;
    --terra-application-navigation-tab-hover-color: #b2b5b6;
    --terra-application-navigation-tab-inner-background-size: 0 5px;
    --terra-application-navigation-tab-inner-padding-bottom: 1.143rem;
    --terra-application-navigation-tab-inner-padding-top: 1.143rem;
    --terra-application-navigation-tab-last-box-shadow: inset 1px 0 0 0 #022b36, inset 2px 0 0 0 #0f699f, inset -1px 0 0 0 #0f699f, inset -2px 0 0 0 #022b36;
  }
}
