// Themes
@import '../clinical-lowlight-theme/NoResults.module';
@import '../orion-fusion-theme/NoResults.module';

:local {
  .no-results {
    overflow: hidden;
    padding: var(--terra-form-select-no-results-padding, 0.28571rem 0.714286rem);
    word-break: break-word;
    word-wrap: break-word;
  }
}
