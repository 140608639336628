:local {
  .orion-fusion-theme {
    --terra-form-field-margin-bottom: 0.5rem;
    --terra-form-field-inline-margin-right: 0.5rem;
    --terra-form-field-label-font-size: 1rem;
    --terra-form-field-label-margin-bottom: 0.5rem;
    --terra-form-field-label-error-icon-display: none;
    --terra-form-field-label-error-icon-padding-right: 0.25rem;
    --terra-form-field-label-color: #1c1f21;
    --terra-form-field-label-font-weight: bold;
    --terra-form-field-label-required-color: #e50000;
    --terra-form-field-label-required-padding-right: 0.25rem;
    --terra-form-field-label-optional-font-style: italic;
    --terra-form-field-label-optional-padding-left: 0.25rem;
    --terra-form-field-help-text-color: rgba(28, 31, 33, 0.65);
    --terra-form-field-help-text-font-size: 0.85rem;
    --terra-form-field-help-text-font-weight: normal;
    --terra-form-field-help-text-margin-top: 0.25rem;
    --terra-form-field-error-text-color: #e50000;
    --terra-form-field-error-text-font-size: 0.85rem;
    --terra-form-field-error-text-font-weight: normal;
    --terra-form-field-error-text-margin-top: 0.25rem;
  }
}
