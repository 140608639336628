:local {
  .orion-fusion-theme {
    --terra-alert-color: #1c1f21;
    --terra-alert-min-height: 3.41667rem;
    --terra-alert-icon-font-size: 1.3333em;
    --terra-alert-icon-margin-left: 0;
    --terra-alert-icon-margin-right: 0.475rem;
    --terra-alert-icon-margin-top: 0;
    --terra-alert-body-margin-left: 0.5rem;
    --terra-alert-body-margin-right: 0.5rem;
    --terra-alert-body-margin-top: 0.75rem;
    --terra-alert-std-margin-bottom: 1rem;
    --terra-alert-std-margin-right: 0.5rem;
    --terra-alert-section-padding-bottom: 0.125rem;
    --terra-alert-section-padding-top: 0.29167rem;
    --terra-alert-custom-text-color: #1c1f21;
    --terra-alert-title-display: block;
    --terra-alert-title-float: left;
    --terra-alert-title-margin-bottom: 0;
    --terra-alert-title-margin-right: 0.5rem;
    --terra-alert-actions-margin-bottom: 1rem;
    --terra-alert-actions-margin-left: 0;
    --terra-alert-actions-margin-right: 0.5rem;
    --terra-alert-actions-margin-top: 1rem;
    --terra-alert-alert-background-color: #fed1d1;
    --terra-alert-alert-box-shadow: inset 2px 0 0 #c00;
    --terra-alert-error-background-color: #fed1d1;
    --terra-alert-error-box-shadow: inset 2px 0 0 #c00;
    --terra-alert-warning-background-color: #fff3ce;
    --terra-alert-warning-box-shadow: inset 2px 0 0 #ffc20a;
    --terra-alert-unsatisfied-background-color: #f4f4f4;
    --terra-alert-unsatisfied-box-shadow: 2px 0 0 #fa0000 inset;
    --terra-alert-unverified-background-color: #f4f4f4;
    --terra-alert-unverified-box-shadow: 2px 0 0 #78288c inset;
    --terra-alert-advisory-background-color: #f4f4f4;
    --terra-alert-advisory-box-shadow: inset 2px 0 0 #9b9fa1;
    --terra-alert-info-background-color: #f4f4f4;
    --terra-alert-info-box-shadow: inset 2px 0 0 #007cc3;
    --terra-alert-success-background-color: #f4f4f4;
    --terra-alert-success-box-shadow: inset 2px 0 0 #78c346;
    --terra-alert-custom-background-color: #f4f4f4;
    --terra-alert-custom-box-shadow: inset 2px 0 0 #9b9fa1;
    --terra-alert-alert-title-color: #1c1f21;
    --terra-alert-error-title-color: #1c1f21;
    --terra-alert-unsatisfied-icon-color: #fa0000;
    --terra-alert-unverified-icon-color: #78288c;
    --terra-alert-custom-default-color: #1c1f21;
  }
}
