:local {

  // opacity and transition styles used to ensure when visibility is transitioned,
  // it appears smoothly
  .toggle[aria-hidden='true'] {
    opacity: 0;
    transition: visibility 0s linear 0.5s,opacity 0.5s linear;
    visibility: hidden;
  }

  // disables focus on focusable children
  .toggle[aria-hidden='false'] {
    opacity: 1;
    transition-delay: 0s;
    visibility: visible;
  }
  
}
