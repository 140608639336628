@import './orion-fusion-theme/IconError.module';
@import './clinical-lowlight-theme/IconError.module';

/* stylelint-disable selector-class-pattern */
:local {
  .IconError {
    path:first-child {
      fill: var(--terra-icon-error-path-1-fill, #e50000);
    }

    path:last-child {
      fill: var(--terra-icon-error-path-2-fill, #fff);
    }
  }
}
/* stylelint-enable selector-class-pattern */
