:local {
  .orion-fusion-theme {
    --terra-card-background-color: #fff;
    --terra-card-border-radius: 0;
    --terra-card-border: 1px solid #c8cacb;
    --terra-card-box-shadow: none;
    --terra-card-color: inherit;
    --terra-card-raised-box-shadow: 2px 2px 0 0 rgba(28, 31, 33, 0.1), -2px 2px 0 0 rgba(28, 31, 33, 0.1), 0 4px 0 0 rgba(28, 31, 33, 0.04), 0 8px 0 -2px rgba(28, 31, 33, 0.03);
  }
}
