:local {
  .clinical-lowlight-theme {
    --terra-abstract-modal-vertical-inset: 10px;
    --terra-abstract-modal-horizontal-inset: 10px;
    --terra-abstract-modal-overlay-background-color: rgba(0, 0, 0, 0.4);
    --terra-abstract-modal-overlay-background-image: none;
    --terra-abstract-modal-overlay-foreground-color: none;
    --terra-abstract-modal-overlay-opacity: none;
  }
}
