:local {
  .clinical-lowlight-theme {
    --terra-application-navigation-drawer-background-color: #222a2e;
    --terra-application-navigation-drawer-count-background-color: #ffda6c;
    --terra-application-navigation-drawer-count-border: 1px solid #ffb20b;
    --terra-application-navigation-drawer-count-color: #000;
    
    --terra-application-navigation-drawer-menu-user-large-avatar-inner-font-size: 1.6521rem;
    --terra-application-navigation-drawer-menu-avatar-outline-border: 0.1429rem solid #181b1d;
    --terra-application-navigation-drawer-menu-background-color: #222a2e;
    --terra-application-navigation-drawer-menu-background-image: none;
  
    --terra-application-navigation-drawer-menu-footer-background-color: #222a2e;
    --terra-application-navigation-drawer-menu-footer-border-top: 1px solid #282d31;
    --terra-application-navigation-drawer-menu-footer-box-shadow: none;
    --terra-application-navigation-drawer-menu-footer-button-active-background-color: #181b1d;
    --terra-application-navigation-drawer-menu-footer-button-active-border-color: #181b1d;
    --terra-application-navigation-drawer-menu-footer-button-active-color: #b2b5b6;
    --terra-application-navigation-drawer-menu-footer-button-background-color: #222a2e;
    --terra-application-navigation-drawer-menu-footer-button-border: 1px solid #181b1d;
    --terra-application-navigation-drawer-menu-footer-button-border-radius: 3px;
    --terra-application-navigation-drawer-menu-footer-button-color: #b2b5b6;
    --terra-application-navigation-drawer-menu-footer-button-focus-background-color: rgba(0, 0, 0, 0.08);
    --terra-application-navigation-drawer-menu-footer-button-focus-border: 1px solid #3496cf;
    --terra-application-navigation-drawer-menu-footer-button-focus-box-shadow: none;
    --terra-application-navigation-drawer-menu-footer-button-focus-color: #b2b5b6;
    --terra-application-navigation-drawer-menu-footer-button-focus-outline: 2px dashed #3496cf;
    --terra-application-navigation-drawer-menu-footer-button-focus-outline-offset: 1px;
    --terra-application-navigation-drawer-menu-footer-button-hover-background-color: rgba(0, 0, 0, 0.13);
    --terra-application-navigation-drawer-menu-footer-button-hover-color: #b2b5b6;
  
    --terra-application-navigation-drawer-menu-header-background-color: transparent;
    --terra-application-navigation-drawer-menu-header-border-bottom: 1px solid #181b1d;
    --terra-application-navigation-drawer-menu-header-fill-background-color: transparent;
  
    --terra-application-navigation-drawer-menu-item-active-background-color: #181b1d;
    --terra-application-navigation-drawer-menu-item-active-color: #b2b5b6;
    --terra-application-navigation-drawer-menu-item-background-color: transparent;
    --terra-application-navigation-drawer-menu-item-color: #b2b5b6;
    --terra-application-navigation-drawer-menu-item-focus-box-shadow: none;
    --terra-application-navigation-drawer-menu-item-focus-outline: 2px dashed #3496cf;
    --terra-application-navigation-drawer-menu-item-focus-outline-offset: -5px;
    --terra-application-navigation-drawer-menu-item-hover-background-color: #181b1d;
    --terra-application-navigation-drawer-menu-item-hover-color: #b2b5b6;
    --terra-application-navigation-drawer-menu-item-selected-box-shadow: inset 0.35714rem 0 0 0 #3496cf;
    --terra-application-navigation-drawer-menu-item-selected-color: #b2b5b6;
    --terra-application-navigation-drawer-menu-item-selected-focus-box-shadow: inset 0.35714rem 0 0 0 #3496cf;
    --terra-application-navigation-drawer-menu-item-selected-focus-outline: 2px dashed #3496cf;
    --terra-application-navigation-drawer-menu-item-selected-focus-outline-offset: -5px;
  
    --terra-application-navigation-drawer-menu-list-background-color: #222a2e;
    --terra-application-navigation-drawer-menu-navigation-list-border-bottom: 1px solid #181b1d;
    --terra-application-navigation-drawer-menu-overflow-background-color: transparent;
    --terra-application-navigation-drawer-menu-user-large-detail-color: #b2b5b6;
    --terra-application-navigation-drawer-menu-user-large-name-color: #b2b5b6;
    --terra-application-navigation-drawer-menu-user-small-detail-color: #b2b5b6;
    --terra-application-navigation-drawer-menu-user-small-name-color: #b2b5b6;
    --terra-application-navigation-drawer-menu-user-small-avatar-inner-font-size: 0.6522rem;
  
    --terra-application-navigation-drawer-title-border-bottom: 0;
    --terra-application-navigation-drawer-title-color: #b2b5b6;
  }
}
