:local {
  .clinical-lowlight-theme {
    --terra-alert-color: #b2b5b6;
    --terra-alert-min-height: 0;
    --terra-alert-icon-font-size: 1.286em;
    --terra-alert-icon-margin-left: 0;
    --terra-alert-icon-margin-right: 0.5rem;
    --terra-alert-icon-margin-top: 0;
    --terra-alert-body-margin-left: 0.64285rem;
    --terra-alert-body-margin-right: 0.7143rem;
    --terra-alert-body-margin-top: 0.7143rem;
    --terra-alert-std-margin-bottom: 0.7143rem;
    --terra-alert-std-margin-right: 0.6667rem;
    --terra-alert-section-padding-bottom: 0.142857rem;
    --terra-alert-section-padding-top: 0.21429rem;
    --terra-alert-custom-text-color: #b2b5b6;
    --terra-alert-title-display: block;
    --terra-alert-title-float: left;
    --terra-alert-title-margin-bottom: 0;
    --terra-alert-title-margin-right: 0.5833rem;
    --terra-alert-actions-margin-bottom: 0.4286rem;
    --terra-alert-actions-margin-left: 0.142857rem;
    --terra-alert-actions-margin-right: 0.5rem;
    --terra-alert-actions-margin-top: 0.4286rem;
    --terra-alert-alert-background-color: #332425;
    --terra-alert-alert-box-shadow: 2px 0 0 #fb4c4c inset;
    --terra-alert-error-background-color: #332425;
    --terra-alert-error-box-shadow: 2px 0 0 #fb4c4c inset;
    --terra-alert-warning-background-color: #464232;
    --terra-alert-warning-box-shadow: 2px 0 0 #ffb166 inset;
    --terra-alert-unsatisfied-background-color: #1a2226;
    --terra-alert-unsatisfied-box-shadow: 2px 0 0 #fa0000 inset;
    --terra-alert-unverified-background-color: #1a2226;
    --terra-alert-unverified-box-shadow: 2px 0 0 #9630af inset;
    --terra-alert-advisory-background-color: #1a2226;
    --terra-alert-advisory-box-shadow: 2px 0 0 #9b9fa1 inset;
    --terra-alert-info-background-color: #1a2226;
    --terra-alert-info-box-shadow: 2px 0 0 #5a81ff inset;
    --terra-alert-success-background-color: #1a2226;
    --terra-alert-success-box-shadow: 2px 0 0 #407d17 inset;
    --terra-alert-custom-background-color: #1a2226;
    --terra-alert-custom-box-shadow: 2px 0 0 #9b9fa1 inset;
    --terra-alert-alert-title-color: #fb4c4c;
    --terra-alert-error-title-color: #fb4c4c;
    --terra-alert-unsatisfied-icon-color: #fa0000;
    --terra-alert-unverified-icon-color: #9630af;
    --terra-alert-custom-default-color: #1c1f21;
  }
}
