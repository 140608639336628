// Themes
@import './clinical-lowlight-theme/Text.module';
@import './orion-fusion-theme/Text.module';

:local {
  .text {
    display: inline-block;
    font-weight: inherit;
  }

  .inherit-color {
    color: inherit;
  }

  // Text Italics
  .italic { font-style: italic; }

  // Word wrap
  .word-wrap {
    max-width: 100%; /* Allows text to wrap in IE */
    overflow-wrap: break-word; /* Modern browsers */
    word-wrap: break-word; /* For IE 10 and IE 11 */
  }

  // Text Sizes
  .font-size-100 { font-size: 7.143rem; }
  .font-size-92 { font-size: 6.571rem; }
  .font-size-72 { font-size: 5.143rem; }
  .font-size-64 { font-size: 4.571rem; }
  .font-size-56 { font-size: 4rem; }
  .font-size-50 { font-size: 3.571rem; }
  .font-size-46 { font-size: 3.286rem; }
  .font-size-36 { font-size: 2.571rem; }
  .font-size-32 { font-size: 2.286rem; }
  .font-size-24 { font-size: 1.714rem;}
  .font-size-20 { font-size: 1.429rem; }
  .font-size-18 { font-size: 1.286rem; }
  .font-size-16 { font-size: 1.143rem; }
  .font-size-14 { font-size: 1rem; }
  .font-size-12 { font-size: 0.8571rem; }
  .font-size-10 { font-size: 0.7143rem; }

  // Text Weights
  .font-weight-200 { font-weight: 200; }
  .font-weight-300 { font-weight: 300; }
  .font-weight-400 { font-weight: 400; }
  .font-weight-700 { font-weight: 700; }


  /**
   * Used to hide the content visually, yet provide the content to screen readers,
   * More info: http://a11yproject.com/posts/how-to-hide-content/
   */
  .visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .font-stack {
    &::after {
      content: var(--terra-text-v2-font-stack-after-content, '\"Helvetica Neue\", Helvetica, Arial, sans-serif');
      display: inline-block;
    }
  }
}
