@import './orion-fusion-theme/IconWarning.module';
@import './clinical-lowlight-theme/IconWarning.module';

/* stylelint-disable selector-class-pattern */
:local {
  .IconWarning {
    path:first-child {
      fill: var(--terra-icon-warning-path-1-fill, #ffc20a);
    }

    path:last-child {
      fill: var(--terra-icon-warning-path-2-fill, #1c1f21);
    }
  }
}
/* stylelint-enable selector-class-pattern */
