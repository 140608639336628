:global {
  .clinical-lowlight-theme {
    --terra-base-background-attachment: none;
    --terra-base-background-color: #222a2e;
    --terra-base-background-image: none;
    --terra-base-background-position: none;
    --terra-base-background-repeat: none;
    --terra-base-background-size: none;
    --terra-base-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    --terra-base-font-size: 87.5%;            // root: 16px, base: 12px
    --terra-base-html-height: 100%;
    --terra-base-html-margin: 0;
    --terra-base-color: #b2b5b6;
    --terra-base-body-height: 100%;
    --terra-base-line-height: 1.4285694285714286;
    --terra-base-body-margin: 0;
  }
}
