// Icon Component
// ====================================================

:local {
  .icon {
    display: inline-block;
    vertical-align: -0.15em; // Adjusts visual alignment for icons to better align next to text
  }

  /**
   * The following selectors match all SVG elements, (path, circle, line, rect, etc), within inline SVG
   * which do not have a predefined fill color.
   */
  .icon *:not([fill]) {
    fill: currentColor; // Allows icon color to be inherited from text color
  }

  // We need dir='rtl'
  // https://github.com/vkalinichev/postcss-rtl/issues/15
  [dir=rtl] .icon.is-bidi {
    transform: scaleX(-1);
  }

  @keyframes tui-spin-ltr {
    0% {
      transform: rotate(0deg);
    }
    
    100% {
      transform: rotate(359deg);
    }
  }

  @keyframes tui-spin-rtl {
    0% {
      transform: rotate(0deg);
    }
    
    100% {
      transform: rotate(-359deg);
    }
  }
}

/* stylelint-disable selector-class-pattern */
:local([dir=ltr] .tui-Icon.is-spin) {
  animation: tui-spin-ltr 3s infinite linear;
}

:local([dir=rtl] .tui-Icon.is-spin) {
  animation: tui-spin-rtl 2s infinite linear;
}
