%triangle-arrow {
  margin-left: -$datepicker-triangle-size;
  position: absolute;

  &,
  &::before {
    border: $datepicker-triangle-size solid transparent;
    box-sizing: content-box;
    height: 0;
    position: absolute;
    width: 1px;
  }

  &::before {
    border-bottom-color: $datepicker-border-color;
    border-width: $datepicker-triangle-size;
    content: '';
    left: -$datepicker-triangle-size;
    z-index: -1;
  }
}

%triangle-arrow-up {
  @extend %triangle-arrow;
  margin-top: -$datepicker-triangle-size;
  top: 0;

  &,
  &::before {
    border-bottom-color: $datepicker-background-color;
    border-top: 0;
  }

  &::before {
    border-bottom-color: $datepicker-border-color;
    top: -1px;
  }
}

%triangle-arrow-down {
  @extend %triangle-arrow;

  bottom: 0;
  margin-bottom: -$datepicker-triangle-size;

  &,
  &::before {
    border-bottom: 0;
    border-top-color: #f0f0f0;
  }

  &::before {
    border-top-color: $datepicker-border-color;
    bottom: -1px;
  }
}
