@import '~terra-mixins/lib/Mixins';

:local {
  .clinical-lowlight-theme {
    --terra-notification-dialog-icon-container-height: 3rem;
    --terra-notification-dialog-icon-container-margin: 0.14285rem 0 0 0;
    --terra-notification-dialog-icon-container-width: 3rem;
    --terra-notification-dialog-hazard-high-icon-height: 3rem;
    --terra-notification-dialog-hazard-high-icon-width: 3rem;
    --terra-notification-dialog-hazard-medium-icon-height: 3rem;
    --terra-notification-dialog-hazard-medium-icon-width: 3rem;
    --terra-notification-dialog-hazard-low-icon-height: 2.57143rem;
    --terra-notification-dialog-hazard-low-icon-margin: 0.21428rem;
    --terra-notification-dialog-hazard-low-icon-width: 2.57143rem;
    --terra-notification-dialog-error-icon-height: 2.57143rem;
    --terra-notification-dialog-error-icon-margin: 0.21428rem;
    --terra-notification-dialog-error-icon-width: 2.57143rem;
    --terra-notification-dialog-custom-icon-height: 2.57143rem;
    --terra-notification-dialog-custom-icon-margin: 0.21428rem;
    --terra-notification-dialog-custom-icon-width: 2.57143rem;

    @include terra-inline-svg-var('--terra-notification-dialog-hazard-high-icon-background', "<svg viewBox='0 0 49 49' xmlns='http://www.w3.org/2000/svg'><path fill='#F94E4E' d='M1.2 45c-1.1 0-1.6-.8-1-1.7L23 3.7c.5-1 1.4-1 2 0l22.8 39.6c.5 1 .1 1.7-1 1.7H1.2z'></path><path fill='#222A2E' d='M21.5 36.7h5V42h-5v-5.3zm0-22.7h5v17.3h-5V14z'></path></svg>");
    @include terra-inline-svg-var('--terra-notification-dialog-hazard-medium-icon-background', "<svg viewBox='0 0 49 49' xmlns='http://www.w3.org/2000/svg'><path fill='#FFB166' d='M1.8 43.5h44.4L24 5 1.8 43.5zM26.5 42h-5v-5.3h5V42zm0-10.7h-5V14h5v17.3z'></path><path fill='#222A2E' d='M47.7 43.3L25 3.7c-.3-.5-.6-.7-1-.7s-.7.2-1 .7L.2 43.3c-.5 1-.1 1.7 1 1.7h45.5c1.1 0 1.6-.8 1-1.7zm-45.9.2L24 5l22.2 38.5H1.8zm19.7-6.8h5V42h-5v-5.3zm0-22.7h5v17.3h-5V14z'></path></svg>");
    @include terra-inline-svg-var('--terra-notification-dialog-hazard-low-icon-background', "<svg viewBox='0 0 49 49' xmlns='http://www.w3.org/2000/svg'><path fill='#5A81FF' d='M24 0c13.3 0 24 10.7 24 24S37.3 48 24 48 0 37.3 0 24C0 10.8 10.7 0 23.9 0h.1z'></path><path fill='#222A2E' d='M24.1 32.7c.7-1.8 6.3-11 2.4-13.5-2.2-1.4-5.2-.5-7.6 1.2l-.2 1.6c1.4-.5 2.8-.5 3.2.7 1 2.9-2.5 8-3.5 10.7-2.6 7.2 3.7 8.1 8.1 5.1l.2-1.7c-2.3.6-4-.3-2.6-4.1zM26.5 8c1.9 0 3.5 1.6 3.5 3.5S28.4 15 26.5 15 23 13.4 23 11.5 24.6 8 26.5 8z'></path></svg>");
    @include terra-inline-svg-var('--terra-notification-dialog-error-icon-background', "<svg viewBox='0 0 49 49' xmlns='http://www.w3.org/2000/svg'><path fill='#F94E4E' d='M24 0c13.3 0 24 10.7 24 24S37.3 48 24 48 0 37.3 0 24C0 10.8 10.7 0 23.9 0h.1z'></path><path fill='#222A2E' d='M7 21.5h34v5H7v-5z'></path></svg>");
  }
}
