:local {
  .clinical-lowlight-theme {
    --terra-application-navigation-extension-active-color: #b2b5b6;
    --terra-application-navigation-extension-color: #b2b5b6;
    --terra-application-navigation-extension-count-background-image: linear-gradient(#ffb20b, #ffc20a);
    --terra-application-navigation-extension-count-border: none;
    --terra-application-navigation-extension-count-box-shadow: 0 1px 0 0 rgba(3, 43, 70, 0.8), -2px 4px 5px 1px rgba(0, 0, 0, 0.33);
    --terra-application-navigation-extension-count-color: #000;
    --terra-application-navigation-extension-focus-background-color: transparent;
    --terra-application-navigation-extension-focus-color: #b2b5b6;
    --terra-application-navigation-extension-focus-outline: 2px dashed #3496cf;
    --terra-application-navigation-extension-focus-outline-offset: -2px;
    --terra-application-navigation-extension-hover-color: #b2b5b6;
    --terra-application-navigation-extension-rollup-active-color: #b2b5b6;
    --terra-application-navigation-extension-rollup-color: #b2b5b6;
    --terra-application-navigation-extension-rollup-focus-background-color: transparent;
    --terra-application-navigation-extension-rollup-focus-color: #b2b5b6;
    --terra-application-navigation-extension-rollup-focus-outline: 2px dashed #3496cf;
    --terra-application-navigation-extension-rollup-focus-outline-offset: -2px;
    --terra-application-navigation-extension-rollup-hover-color: #b2b5b6;
  }
}
