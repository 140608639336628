//Themes
@import './clinical-lowlight-theme/Card.module';
@import './orion-fusion-theme/Card.module';

:local {
  .card {
    background-color: var(--terra-card-background-color, #fff);
    border: var(--terra-card-border, 1px solid #c8cacb);
    border-radius: var(--terra-card-border-radius, 3px);
    color: var(--terra-card-color);
    overflow: hidden;
  }

  .default {
    box-shadow: var(--terra-card-box-shadow, none);
  }

  .raised {
    box-shadow: var(--terra-card-raised-box-shadow, 0 3px 8px -2px rgba(0, 0, 0, 0.18), 0 3px 10px 0 rgba(0, 0, 0, 0.12), 0 3px 3px -3px rgba(0, 0, 0, 0.3));
  }
}
