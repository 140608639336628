// Themes
@import './clinical-lowlight-theme/Overlay.module';
@import './orion-fusion-theme/Overlay.module';

:local {
  .overlay-container {
    outline: none;
    position: relative;
  }

  .overlay {
    align-items: center;
    display: flex;
    justify-content: center;
    outline: none;
    overflow: hidden;

    &.layer-100 {
      z-index: 100;
    }

    &.layer-6000 {
      z-index: 6000;
    }

    &.layer-7000 {
      z-index: 7000;
    }

    &.layer-8000 {
      z-index: 8000;
    }

    &.layer-9000 {
      z-index: 9000;
    }

    &.is-fullscreen {
      height: 100%;
      width: 100%;
    }
  }

  .clear {
    background: rgba(255, 255, 255, 0.001);
  }

  .dark {
    background: var(--terra-overlay-dark-background, rgba(28, 31, 33, 0.8));
    background-image: var(--terra-overlay-dark-background-image, none);

    .content {
      color: var(--terra-overlay-dark-content-color, #fff);
      text-shadow: var(--terra-overlay-dark-content-text-shadow, none);
    }
  }

  .light {
    background: var(--terra-overlay-light-background, rgba(245, 245, 245, 0.7));
    background-image: var(--terra-overlay-light-background-image, none);

    .content {
      color: var(--terra-overlay-light-content-color, #1c1f21);
      text-shadow: var(--terra-overlay-light-content-text-shadow, none);
    }
  }

  .fullscreen {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }

  .container {
    height: 100%;
    position: absolute;
    width: 100%;
  }

  .container-content {
    height: 100%;
  }

  .scrollable {
    overflow-y: scroll;
  }

  .content {
    color: var(--terra-overlay-content-color, #1c1f21);
    font-size: 1rem;
    max-height: 100%;
    padding-bottom: var(--terra-overlay-content-padding-bottom, 1rem);
    padding-left: var(--terra-overlay-content-padding-left, 1rem);
    padding-right: var(--terra-overlay-content-padding-right, 1rem);
    padding-top: var(--terra-overlay-content-padding-top, 1rem);
  }

  .icon {
    display: block !important;
    margin: auto;
  }

  .message {
    padding: 5px;
  }

  .background-scroll-content {
    bottom: 0;
    left: 0;
    overflow: -moz-scrollbars-none;
    // This is needed on iOS so that this content area will catch momentum scrolling
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    overflow-x: scroll;
    overflow-y: scroll;
    position: fixed;
    right: 0;
    top: 0;

    &::-webkit-scrollbar {
      display: none;
    }

    .inner {
      height: 200%;
      left: 0;
      position: absolute;
      top: 0;
      width: 200%;
    }
  }
}
