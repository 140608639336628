// Themes
@import './orion-fusion-theme/AbstractModal.module';
@import './clinical-lowlight-theme/AbstractModal.module';

:local {
  .overlay {
    background-color: var(--terra-abstract-modal-overlay-background-color, rgba(0, 0, 0, 0.4));
    background-image: var(--terra-abstract-modal-overlay-background-image);
    bottom: 0;
    color: var(--terra-abstract-modal-overlay-foreground-color);
    left: 0;
    opacity: var(--terra-abstract-modal-overlay-opacity);
    position: absolute;
    right: 0;
    top: 0;

    &.layer-6000 {
      z-index: 6000;
    }

    &.layer-7000 {
      z-index: 7000;
    }

    &.layer-8000 {
      z-index: 8000;
    }

    &.layer-9000 {
      z-index: 9000;
    }
  }
}
