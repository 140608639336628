:local {
  .orion-fusion-theme {
    --terra-image-rounded-border-radius: 0.25rem;
    --terra-image-circle-border-radius: 50%;
    --terra-image-thumbnail-background-color: #fff;
    --terra-image-thumbnail-border-color: #dedfe0;
    --terra-image-thumbnail-border-radius: 0.25rem;
    --terra-image-thumbnail-border-style: solid;
    --terra-image-thumbnail-border-width: 1px;
    --terra-image-thumbnail-padding-bottom: 0.25rem;
    --terra-image-thumbnail-padding-left: 0.25rem;
    --terra-image-thumbnail-padding-right: 0.25rem;
    --terra-image-thumbnail-padding-top: 0.25rem;
  }
}

