:local {
  .clinical-lowlight-theme {
    --terra-notification-dialog-background: #222a2e;
    --terra-notification-dialog-border-radius: 3px;
    --terra-notification-dialog-small-horizontal-inset: 17px;
    --terra-notification-dialog-max-width: 500px;
    --terra-notification-dialog-floating-background-height: 5.78571rem;
    --terra-notification-dialog-hazard-high-background-image: linear-gradient(to bottom, #1f2629, #222a2e);
    --terra-notification-dialog-hazard-high-border-top: 7px solid #fb4c4c;
    --terra-notification-dialog-hazard-medium-background-image: linear-gradient(to bottom, #1f2629, #222a2e);
    --terra-notification-dialog-hazard-medium-border-top: 7px solid #ffb166;
    --terra-notification-dialog-hazard-low-background-image: linear-gradient(to bottom, #1f2629, #222a2e);
    --terra-notification-dialog-hazard-low-border-top: 7px solid #434a4d;
    --terra-notification-dialog-error-background-image: linear-gradient(to bottom, #1f2629, #222a2e);
    --terra-notification-dialog-error-border-top: 7px solid #434a4d;
    --terra-notification-dialog-custom-background-image: linear-gradient(to bottom, #1f2629, #222a2e);
    --terra-notification-dialog-header-box-shadow: -16px 8px 5px 0 rgba(34, 42, 46, 0.5), -16px 2px 2px 0 rgba(34, 42, 46, 0.75);
    --terra-notification-dialog-custom-border-top: 7px solid #434a4d;
    --terra-notification-dialog-header-padding: 0 1.42857rem;
    --terra-notification-dialog-header-content-border-bottom: 1px solid #434a4d;
    --terra-notification-dialog-header-content-padding: 1.21428rem 0 0.5rem;
    --terra-notification-dialog-header-container-margin-left: 0.5rem;
    --terra-notification-dialog-signal-word-color: #b2b5b6;
    --terra-notification-dialog-signal-word-font-size: 1.42857rem;
    --terra-notification-dialog-signal-word-min-height: 1.64285rem;
    --terra-notification-dialog-title-color: #b2b5b6;
    --terra-notification-dialog-title-font-size: 1.2857rem;
    --terra-notification-dialog-title-font-weight: normal;
    --terra-notification-dialog-title-margin: 0;
    --terra-notification-dialog-title-min-height: 1.5rem;
    --terra-notification-dialog-body-background-color: #222a2e;
    --terra-notification-dialog-body-padding: 0 1.64285rem;
    --terra-notification-dialog-body-media-small-min-height: 4.5rem;
    --terra-notification-dialog-body-media-small-padding: 0.85714rem 1.64285rem 1.07142rem;
    --terra-notification-dialog-body-media-large-min-height: 7rem;
    --terra-notification-dialog-message-color: #b2b5b6;
    --terra-notification-dialog-message-font-size: 1rem;
    --terra-notification-dialog-message-padding: 0.35714rem 0;
    --terra-notification-dialog-message-text-align: left;
    --terra-notification-dialog-footer-background-color: #1e2427;
    --terra-notification-dialog-footer-border-top: 1px solid #434a4d;
    --terra-notification-dialog-footer-box-shadow: -16px -9px 5px 0 rgba(34, 42, 46, 0.5), -16px -3px 3px 0 rgba(34, 42, 46, 0.75);
    --terra-notification-dialog-footer-min-height: 0.71428rem;
    --terra-notification-dialog-actions-padding: 0.64285rem 0.71428rem;
  }
}
