@import '~terra-mixins/lib/Mixins';

// Themes
@import './clinical-lowlight-theme/ContentLayoutAsList.module';
@import './orion-fusion-theme/ContentLayoutAsList.module';

:local {
  .list {
    list-style: var(--terra-notification-dialog-content-list-style, outside disc);
    margin: 0;
    padding: var(--terra-notification-dialog-content-list-padding, 0 0 0 1.85714rem);
  }

  .list-item {
    margin: 0;
    padding: 0;
  }

  .list-item + .list-item {
    margin: var(--terra-notification-dialog-content-list-item-margin, 0.35714rem 0 0 0);
  }
}
