@import '~terra-mixins/lib/Mixins';

:local {
  .orion-fusion-theme {
    --terra-form-select-background-color: #fff;
    --terra-form-select-border-radius: 3px;
    --terra-form-select-color: #1c1f21;
    --terra-form-select-font-size: 1rem;
    --terra-form-select-line-height: 2;
    --terra-form-select-background-size: 0 0;
    --terra-form-select-border: 1px solid #dedfe0;
    --terra-form-select-padding-bottom: 0;
    --terra-form-select-padding-left: 0.25rem;
    --terra-form-select-padding-right: 0;
    --terra-form-select-padding-top: 0;
    --terra-form-select-focus-background-image: none;
    --terra-form-select-focus-background-size: 0 0;
    --terra-form-select-focus-border-color: #4cb2e9;
    --terra-form-select-focus-box-shadow: 0 0 4px #a6d9f4;
    --terra-form-select-focus-transition-duration: 0s;
    --terra-form-select-focus-transition-timing-function: ease;
    --terra-form-select-open-border-top-left-radius: 3px;
    --terra-form-select-open-border-top-right-radius: 3px;
    --terra-form-select-above-border-bottom-left-radius: 3px;
    --terra-form-select-above-border-bottom-right-radius: 3px;
    --terra-form-select-hover-border-color: #dedfe0;
    --terra-form-select-hover-color: #1c1f21;
    --terra-form-select-focus-hover-border-color: #4cb2e9;
    --terra-form-select-incomplete-background-color: #fefd9a;
    --terra-form-select-incomplete-focus-background-color: #fefd9a;
    --terra-form-select-incomplete-hover-background-color: #fefd9a;
    --terra-form-select-incomplete-disabled-background-color: #fefd9a;
    --terra-form-select-invalid-background-color: none;
    --terra-form-select-invalid-background-image: none;
    --terra-form-select-invalid-border-color: #dedfe0;
    --terra-form-select-invalid-box-shadow: none;
    --terra-form-select-invalid-focus-background-size: 0 0;
    --terra-form-select-invalid-focus-border-color: #4cb2e9;
    --terra-form-select-invalid-focus-box-shadow: 0 0 4px #a6d9f4;
    --terra-form-select-invalid-hover-border-color: #4cb2e9;
    --terra-form-select-arrow-height: 0.66667rem;
    --terra-form-select-arrow-width: 0.66667rem;
    --terra-form-select-disabled-background-color: rgba(111, 116, 119, 0.2);
    --terra-form-select-disabled-border-color: #dedfe0;
    --terra-form-select-disabled-color: #1c1f21;
    --terra-form-select-disabled-opacity: 0.4;
    --terra-form-select-disabled-placeholder-color: #767676;
    --terra-form-select-disabled-placeholder-font-style: normal;
    --terra-form-select-toggle-padding: 0.5rem;
    --terra-form-select-content-padding-bottom: 0.25rem;
    --terra-form-select-content-padding-left: 0;
    --terra-form-select-content-padding-right: 0;
    --terra-form-select-content-padding-top: 0;
    --terra-form-select-search-focus-font-size: 1rem;
    --terra-form-select-search-focus-height: 1.5rem;
    --terra-form-select-search-focus-margin-top: 0.25rem;
    --terra-form-select-search-placeholder-color: #707070;
    --terra-form-select-search-placeholder-font-size: 1rem;
    --terra-form-select-search-placeholder-font-style: normal;
    --terra-form-select-border-bottom-left-radius: 3px;
    --terra-form-select-border-top-left-radius: 3px;
    --terra-form-select-toggle-border: 1px solid #dedfe0;
    --terra-form-select-border-bottom-right-radius: 3px;
    --terra-form-select-border-top-right-radius: 3px;
    --terra-form-select-toggle-hover-background-color: none;
    --terra-form-select-toggle-invalid-border-color: #dedfe0;
    --terra-form-select-toggle-invalid-box-shadow: none;
    --terra-form-select-toggle-invalid-focus-border-color: #dedfe0;
    --terra-form-select-toggle-invalid-focus-box-shadow: none;

    @include terra-inline-svg-var('--terra-form-select-arrow-background', '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" class="is-bidi"><path d="M48 12L24 36 0 12h48z"/></svg>');
    @include terra-inline-svg-var('--terra-form-select-disabled-arrow-background', '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" class="is-bidi"><path d="M48 12L24 36 0 12h48z"/></svg>');
    @include terra-inline-svg-var('--terra-form-select-invalid-arrow-background', '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" class="is-bidi"><path d="M48 12L24 36 0 12h48z"/></svg>');
  }
}
