@import '~terra-mixins/lib/Mixins';

// import themes
@import './clinical-lowlight-theme/ActionHeader.module';
@import './orion-fusion-theme/ActionHeader.module';

:local {
  .left-buttons > button {
    margin-left: var(--terra-action-header-left-buttons-margin-left, 0.35714rem);
    margin-right: var(--terra-action-header-left-buttons-margin-right, 0);
  }

  .right-buttons > button {
    margin-left: var(--terra-action-header-right-buttons-margin-left, 0.5rem);
  }

  .header-button {
    min-height: var(--terra-action-header-buttons-min-height, 1.429);
    padding-bottom: var(--terra-action-header-buttons-padding-bottom, 0.286rem); // needed for alternate theme button overrides
    padding-top: var(--terra-action-header-buttons-padding-top, 0.286rem); // needed for alternate theme button overrides
    
    > span {
      line-height: var(--terra-action-header-buttons-inner-span-line-height); // needed for alternate theme button overrides
      margin: var(--terra-action-header-buttons-inner-span-margin, 0 0.5rem); // needed for alternate theme button overrides
    }
  }

  .previous-next-button-group {
    display: inline-flex;
    margin-left: var(--terra-action-header-previous-next-button-group-margin-left, 0.35714rem);
    margin-right: var(--terra-action-header-previous-next-button-group-margin-right, 0);
    position: relative;
    vertical-align: middle;
    white-space: nowrap;
  }

  .back-button + .previous-next-button-group,
  .maximize-button + .previous-next-button-group,
  .minimize-button + .previous-next-button-group {
    margin-left: var(--terra-action-header-buttons-plus-previous-next-buttons-margin-left, 0.5rem);
  }

  .header-icon {
    background-repeat: no-repeat;
    background-size: auto;
    display: inline-block;
    height: var(--terra-action-header-icon-height, 1rem); // needed for different icon size in alternate themes
    position: relative;
    top: var(--terra-action-header-icon-top, 0);
    vertical-align: var(--terra-action-header-icon-vertical-align, -0.14285rem);
    width: var(--terra-action-header-icon-width, 1rem); // needed for different icon size in alternate themes

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      height: var(--terra-action-header-icon-ms-height, 1rem);
      vertical-align: var(--terra-action-header-icon-ms-vertical-align, -0.17285rem);
    }

    &.back {
      background-image: var(--terra-action-header-back-background-image, inline-svg('<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M48 21H10.6L27.3 3.9 23.5.1 0 24l23.5 23.9 3.8-3.8L10.6 27H48z"></path></svg>'));

      &:hover {
        background-image: var(--terra-action-header-back-hover-background-image, inline-svg('<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M48 21H10.6L27.3 3.9 23.5.1 0 24l23.5 23.9 3.8-3.8L10.6 27H48z"></path></svg>'));
      }
    }

    &.close {
      background-image: var(--terra-action-header-close-background-image, inline-svg('<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M28.2 24L42.9 9.1 40.8 7l-1.7-1.6-.4-.5L24 19.7 9.4 4.9 7.2 7 5.6 8.6l-.5.5L19.8 24 5.1 38.9 7.2 41l1.7 1.6.5.5L24 28.3l14.7 14.8.4-.5 1.7-1.6 2.1-2.1L28.2 24z"></path></svg>'));
    
      &:hover {
        background-image: var(--terra-action-header-close-hover-background-image, inline-svg('<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M28.2 24L42.9 9.1 40.8 7l-1.7-1.6-.4-.5L24 19.7 9.4 4.9 7.2 7 5.6 8.6l-.5.5L19.8 24 5.1 38.9 7.2 41l1.7 1.6.5.5L24 28.3l14.7 14.8.4-.5 1.7-1.6 2.1-2.1L28.2 24z"></path></svg>'));
      }
    }

    &.maximize {
      background-image: var(--terra-action-header-maximize-background-image, inline-svg('<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M17.7 26.1L6.9 36.9 0 30v18h18l-6.9-6.9 10.8-10.8zM48 0H30l6.9 6.9-10.8 10.8 4.2 4.2 10.8-10.8L48 18z"></path></svg>'));

      &:hover {
        background-image: var(--terra-action-header-maximize-hover-background-image, inline-svg('<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M17.7 26.1L6.9 36.9 0 30v18h18l-6.9-6.9 10.8-10.8zM48 0H30l6.9 6.9-10.8 10.8 4.2 4.2 10.8-10.8L48 18z"></path></svg>'));
      }
    }

    &.minimize {
      background-image: var(--terra-action-header-minimize-background-image, inline-svg('<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M10.8 32.9L0 43.7 4.2 48l10.9-10.8 6.9 6.9V26H3.9zM48 4.2L43.8 0 32.9 10.8 26 3.9V22h18.1l-6.9-6.9z"></path></svg>'));

      &:hover {
        background-image: var(--terra-action-header-minimize-hover-background-image, inline-svg('<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M10.8 32.9L0 43.7 4.2 48l10.9-10.8 6.9 6.9V26H3.9zM48 4.2L43.8 0 32.9 10.8 26 3.9V22h18.1l-6.9-6.9z"></path></svg>'));
      }
    }

    &.previous {
      background-image: var(--terra-action-header-previous-background-image, inline-svg('<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M24 10.3l24 23.5-3.8 3.9L24 18 3.8 37.7 0 33.8z"></path></svg>'));

      &:hover {
        background-image: var(--terra-action-header-previous-hover-background-image, inline-svg('<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M24 10.3l24 23.5-3.8 3.9L24 18 3.8 37.7 0 33.8z"></path></svg>'));
      }
    }

    &.next {
      background-image: var(--terra-action-header-next-background-image, inline-svg('<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M24 37.7L0 14.2l3.8-3.9L24 30l20.2-19.7 3.8 3.9z"></path></svg>'));

      &:hover {
        background-image: var(--terra-action-header-next-hover-background-image, inline-svg('<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M24 37.7L0 14.2l3.8-3.9L24 30l20.2-19.7 3.8 3.9z"></path></svg>'));
      }
    }
  }
}
