:local {
  .clinical-lowlight-theme {
    --terra-application-navigation-utility-header-button-active-color: #c5c5c6;
    --terra-application-navigation-utility-header-button-color: #c5c5c6;
    --terra-application-navigation-utility-header-button-focus-background-color: transparent;
    --terra-application-navigation-utility-header-button-focus-color: #c5c5c6;
    --terra-application-navigation-utility-header-button-focus-outline: 2px dashed #3496cf;
    --terra-application-navigation-utility-header-button-focus-outline-offset: 3px;
    --terra-application-navigation-utility-header-button-hover-color: #c5c5c6;
    --terra-application-navigation-utility-header-button-no-user-focus-outline-offset: -2px;
  }
}
