:local {
  .clinical-lowlight-theme {
    --terra-card-background-color: #222a2e;
    --terra-card-border-radius: 3px;
    --terra-card-border: 1px solid #181b1d;
    --terra-card-box-shadow: none;
    --terra-card-color: #b2b5b6;
    --terra-card-raised-box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.18), 0 3px 10px 0 rgba(0, 0, 0, 0.12), 0 3px 3px -3px rgba(0, 0, 0, 0.3);
  }
}
