$datepicker-background-color: #f0f0f0 !default;
$datepicker-border-color: #aeaeae !default;
$datepicker-highlighted-color: #3dcc4a !default;
$datepicker-muted-color: #ccc !default;
$datepicker-selected-color: #0079be !default;
$datepicker-selected-hover-color: #0065a3 !default;
$datepicker-text-color: #000 !default;
$datepicker-header-color: #000 !default;

$datepicker-border-radius: 0.3rem !default;
$datepicker-day-margin: 0.166rem !default;
$datepicker-font-size: 0.8rem !default;
$datepicker-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$datepicker-item-size: 1.7rem !default;
$datepicker-margin: 0.4rem !default;
$datepicker-navigation-size: 0.45rem !default;
$datepicker-triangle-size: 8px !default;
