:local {
  .orion-fusion-theme {
    // Default
    --terra-date-picker-button-border: 1px solid #dedfe0;
    --terra-date-picker-button-border-bottom-right-radius: 3px;
    --terra-date-picker-button-border-top-right-radius: 3px;
    --terra-date-picker-button-box-shadow: inset 0 3rem 1.5rem -2rem #dedfe0;
    --terra-date-picker-button-color: #000;

    // Default - Focus
    --terra-date-picker-input-focus-border-color: #4cb2e9;
    --terra-date-picker-button-focus-border-color: #26a2e5;
    --terra-date-picker-button-focus-box-shadow: inset 0 3rem 1.5rem -2rem #f4f4f4, 0 0 1px 1px rgba(255, 255, 255, 0.65), 0 0 2px 3px rgba(76, 178, 233, 0.5), 0 0 6px 4px rgba(76, 178, 233, 0.3);

    // Invalid
    --terra-date-picker-input-invalid-border-color: #dedfe0;
    --terra-date-picker-input-invalid-box-shadow: none;
    --terra-date-picker-button-invalid-border-color: #dedfe0;
    --terra-date-picker-button-invalid-box-shadow: none;

    // Invalid - Focus
    --terra-date-picker-input-invalid-focus-border-color: #4cb2e9;
    --terra-date-picker-input-invalid-focus-box-shadow: 0 0 8px #a6d9f4;
    --terra-date-picker-button-invalid-focus-border-color: #4cb2e9;
    --terra-date-picker-button-invalid-focus-box-shadow: inset 0 3rem 1.5rem -2rem #f4f4f4, 0 0 1px 1px rgba(255, 255, 255, 0.65), 0 0 2px 3px rgba(76, 178, 233, 0.5), 0 0 6px 4px rgba(76, 178, 233, 0.3);
  
    // Incomplete 
    --terra-date-picker-input-incomplete-background-color: #fefd9a;
    --terra-date-picker-input-incomplete-hover-background-color: #fefd9a;
    --terra-date-picker-input-incomplete-focus-background-color: #fefd9a;
  
    // Incomplete - Disabled
    --terra-date-picker-input-incomplete-disabled-background-color: #f4f4f4;
    --terra-date-picker-input-incomplete-disabled-border: 1px solid #b2b5b6;
    --terra-date-picker-input-incomplete-disabled-color: #9b9fa1;
    --terra-date-picker-input-incomplete-disabled-opacity: 1;

    // format text
    --terra-date-picker-format-text-color: rgba(28, 31, 33, 0.65);
    --terra-date-picker-format-text-font-size: 0.855rem;
    --terra-date-picker-format-text-font-weight: normal;
    --terra-date-picker-format-text-margin-top: 0.25em;
  }
}
