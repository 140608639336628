@import './orion-fusion-theme/IconInformation.module';
@import './clinical-lowlight-theme/IconInformation.module';

/* stylelint-disable selector-class-pattern */
:local {
  .IconInformation {
    path:first-child {
      fill: var(--terra-icon-information-path-1-fill, #007cc3);
    }

    path:last-child {
      fill: var(--terra-icon-information-path-2-fill, #fff);
    }
  }
}
/* stylelint-enable selector-class-pattern */
