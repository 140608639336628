:local {
  .orion-fusion-theme {
    --terra-divider-border-color: #dedfe0;
    --terra-divider-border-top-width: 1px;
    --terra-divider-text-color: #64696c;
    --terra-divider-text-font-size: 1rem;
    --terra-divider-text-font-style: normal;
    --terra-divider-text-font-weight: 700;
    --terra-divider-text-line-height: 1.417;
    --terra-divider-text-max-width: 80em;
    --terra-divider-text-padding-left: 1rem;
    --terra-divider-text-padding-right: 1rem;
  }
}
